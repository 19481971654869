import React from 'react';

export default function Kentico(): JSX.Element {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16587 1.74216V6.8542C4.16607 6.89934 4.15051 6.94313 4.12187 6.97803C4.09323 7.01292 4.05331 7.03673 4.00899 7.04533C3.35714 7.16885 0.9021 7.49613 0.9021 5.42246V1.7692C0.9021 -0.243156 4.16587 -0.288235 4.16587 1.74576"
        fill="white"
      />
      <path
        d="M8.63235 5.26633V10.3793C8.63247 10.4244 8.61675 10.4682 8.58792 10.503C8.55909 10.5377 8.51898 10.5613 8.47457 10.5695C7.82272 10.6939 5.36768 11.0203 5.36768 8.94663V5.28977C5.36768 3.27831 8.63235 3.23323 8.63235 5.26633Z"
        fill="white"
      />
      <path
        d="M13.0979 8.35983V13.4728C13.0981 13.518 13.0824 13.5619 13.0536 13.5969C13.0248 13.6318 12.9846 13.6555 12.9401 13.6639C12.2883 13.7874 9.83325 14.1147 9.83325 12.041V8.38327C9.83325 6.37182 13.0979 6.32674 13.0979 8.35983Z"
        fill="white"
      />
    </svg>
  );
}
