/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of, zip } from 'rxjs';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { AppState } from 'store';
import { showError, showSuccessful } from 'store/notify/notify.actions';
import { setPaginationSearchSpinner } from 'store/table/table.actions';
import { api } from 'utils/axiosInstance';
import { CreateOrUpdateProjectDTO, KontentContentElementTypeDTO, KontentContentTypeDTO, KontentLanguageDTO, KontentWebhookDTO, KontentWorkflowStepDTO, XTMLanguageDTO } from 'utils/restApplicationClient';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addProject,
  addProjectError,
  addProjectSuccess,
  connectToKentico,
  connectToKenticoError,
  connectToKenticoSuccess,
  editProject,
  editProjectError,
  editProjectSuccess,
  getApiKey,
  getApiKeyError,
  getApiKeySuccess,
  getContentElements,
  getContentElementsError,
  getContentElementsSuccess,
  getContentTypes,
  getContentTypesError,
  getContentTypesSuccess,
  getKenticoLanguages,
  getKenticoLanguagesError,
  getKenticoLanguagesSuccess,
  getProjectById,
  getProjectByIdError,
  getProjectByIdSuccess,
  GetProjectsByClientId,
  GetProjectsByClientIdError,
  GetProjectsByClientIdSuccess,
  getProjectsPageable,
  getProjectsPageableError,
  getProjectsPageableSuccess,
  getWebhooks,
  getWebhooksError,
  getWebhooksSuccess,
  getWorkflowSteps,
  getWorkflowStepsError,
  getWorkflowStepsSuccess,
  getXtmLanguages,
  getXtmLanguagesError,
  getXtmLanguagesSuccess,
  allFormObservers,
  clearProjectFormAll,
} from './project.actions';

export const getProjectsPageableEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getProjectsPageable.type),
    switchMap(({ payload }) =>
      from(api.getAllProjects(payload)).pipe(
        switchMap(({ data }) => of(getProjectsPageableSuccess(data), setPaginationSearchSpinner(false))),
        catchError(({ response }) =>
          of(getProjectsPageableError({ error: response }), setPaginationSearchSpinner(false)),
        ),
      ),
    ),
  );

export const getWorkflowStepsEpic: Epic<typeof getWorkflowSteps, any, AppState> = (action$, state$) =>
  action$.pipe(
    ofType(getWorkflowSteps.type),
    switchMap(() =>
      from(api.getWorkflowStepList(state$.value.project.connectedProjectId)).pipe(
        switchMap(({ data }) => of(getWorkflowStepsSuccess(data))),
        catchError(({ response }) => of(getWorkflowStepsError({ error: response }))),
      ),
    ),
  );

export const getContentTypesEpic: Epic<typeof getContentTypes, any, AppState> = (action$, state$) =>
  action$.pipe(
    ofType(getContentTypes.type),
    switchMap(() =>
      from(api.getContentTypesListByKontentProject(state$.value.project.connectedProjectId)).pipe(
        switchMap(({ data }) => of(getContentTypesSuccess(data))),
        catchError(({ response }) => of(getContentTypesError({ error: response }))),
      ),
    ),
  );

export const getContentElementsEpic: Epic<typeof getContentElements, any, AppState> = (action$) =>
  action$.pipe(
    ofType(getContentElements.type),
    switchMap(() =>
      from(api.getContentElementTypesList()).pipe(
        switchMap(({ data }) => of(getContentElementsSuccess(data))),
        catchError(({ response }) => of(getContentElementsError({ error: response }))),
      ),
    ),
  );

export const getWebhooksEpic: Epic<typeof getWebhooks, any, AppState> = (action$, state$) =>
  action$.pipe(
    ofType(getWebhooks.type),
    switchMap(() =>
      from(api.getWebhookList(state$.value.project.connectedProjectId)).pipe(
        switchMap(({ data }) => of(getWebhooksSuccess(data))),
        catchError(({ response }) => of(getWebhooksError({ error: response }))),
      ),
    ),
  );

export const connectToKenticoEpic: Epic = (action$) =>
  action$.pipe(
    ofType(connectToKentico.type),
    switchMap(({ payload: { managementApiKey, deliveryApiKey, kontentProjectId } }) =>
      from(
        api.connectKontentProject({
          managementApiKey,
          deliveryApiKey,
          kontentProjectId,
        }),
      ).pipe(
        switchMap(() => of(connectToKenticoSuccess(kontentProjectId))),
        catchError(({ response }) => {
          if (response.status === 410) {
            return of(connectToKenticoError({ error: response }), clearProjectFormAll(), showError({ message: 'projects.errors.removed'}));
          }
          return of(connectToKenticoError({ error: response }));
        }),
      ),
    ),
  );

export const getApiKeyEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getApiKey.type),
    switchMap(({ payload }) =>
      from(api.getApiKey(payload)).pipe(
        switchMap(({ data }) => of(getApiKeySuccess(data))),
        catchError(({ response }) => of(getApiKeyError({ error: response }))),
      ),
    ),
  );

export const addProjectEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(addProject.type),
    switchMap(({ payload }: { payload: CreateOrUpdateProjectDTO }) =>
      from(api.createProject(payload)).pipe(
        switchMap(() => {
          browserHistory.push('/projects');

          return of(addProjectSuccess(), showSuccessful({ message: 'projects.success.create' }));
        }),
        catchError(({ response, response: { status } }) =>
          of(addProjectError({ error: response, skipMessage: status === 409 })),
        ),
      ),
    ),
  );

export const editProjectEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(editProject.type),
    switchMap(({ payload: { projectId, projectData } }) =>
      from(api.updateProject(projectId, projectData)).pipe(
        switchMap(() => {
          browserHistory.push('/projects');

          return of(editProjectSuccess(), showSuccessful({ message: 'projects.success.update' }));
        }),
        catchError(({ response, response: { status } }) =>
          of(editProjectError({ error: response, skipMessage: status === 409 })),
        ),
      ),
    ),
  );

export const getProjectByIdEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(getProjectById.type),
    switchMap(({ payload }) =>
      from(api.getProject(payload)).pipe(
        switchMap(({ data }) => of(getProjectByIdSuccess(data))),
        catchError(({ response }) => {
          browserHistory.push('/projects');
          return of(getProjectByIdError({ error: response }));
        }),
      ),
    ),
  );

export const getKenticoLanguagesEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getKenticoLanguages.type),
    switchMap(({ payload }) =>
      from(api.getProjectLanguageListByKontentProject(payload)).pipe(
        switchMap(({ data }) => of(getKenticoLanguagesSuccess(data))),
        catchError(({ response }) => {
          return of(getKenticoLanguagesError({ error: response }));
        }),
      ),
    ),
  );

  export const parseEntriesEpic: Epic = (action$) =>
  zip(
    action$.ofType(getWebhooksSuccess.type),
    action$.ofType(getWorkflowStepsSuccess.type),
    action$.ofType(getContentTypesSuccess.type),
    action$.ofType(getContentElementsSuccess.type),
    action$.ofType(getKenticoLanguagesSuccess.type),
    action$.ofType(getXtmLanguagesSuccess.type),
    ).pipe(
      mergeMap<
        [
          PayloadAction<KontentWebhookDTO[]>,
          PayloadAction<KontentWorkflowStepDTO[]>,
          PayloadAction<KontentContentTypeDTO[]>,
          PayloadAction<KontentContentElementTypeDTO[]>,
          PayloadAction<KontentLanguageDTO[]>,
          PayloadAction<XTMLanguageDTO[]>,
        ],
        any
      >(
        ([
          {payload: webhooks},
          {payload: workflowSteps},
          {payload: contentTypes},
          {payload: contentElements},
          {payload: kenticoLanguages},
          {payload: xtmLanguages},
        ]) => {
          let observer = {
            webhooks,
            workflowSteps,
            contentTypes,
            contentElements,
            kenticoLanguages,
            xtmLanguages,
          }
          return of(allFormObservers(observer))
        }
      )
    );

const getXtmLanguagesEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getXtmLanguages.type),
    switchMap(() =>
      from(api.getLanguages()).pipe(
        switchMap(({ data }) => {
          return of(getXtmLanguagesSuccess(data));
        }),
        catchError(({ response }) => of(getXtmLanguagesError({ error: response }))),
      ),
    ),
  );

const getProjectsByClientIdEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GetProjectsByClientId.type),
    switchMap(({ payload: { clientUUID } }) =>
      from(api.getAllProjectsByClientId({ clientUUID })).pipe(
        switchMap(({ data }) => {
          return of(GetProjectsByClientIdSuccess(data));
        }),
        catchError(({ response }) => of(GetProjectsByClientIdError({ error: response }))),
      ),
    ),
  );

export const projectEpics = combineEpics(
  getProjectsPageableEpic,
  getWebhooksEpic,
  getWorkflowStepsEpic,
  connectToKenticoEpic,
  addProjectEpic,
  editProjectEpic,
  getContentTypesEpic,
  getContentElementsEpic,
  getKenticoLanguagesEpic,
  getXtmLanguagesEpic,
  getApiKeyEpic,
  getProjectByIdEpic,
  getProjectsByClientIdEpic,
  parseEntriesEpic,
);
