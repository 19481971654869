/** @jsx jsx */
import { jsx } from '@emotion/core';
import { CircularProgress } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Link from 'icons/Link';
import LinkBroken from 'icons/LinkBroken';
import { Component, Fragment } from 'react';
import { accordionStyles } from './Accordion.style';

interface IProps {
  connected: boolean;
  spinner?: boolean;
  error?: boolean;
  touched?: boolean;
}

class AccordionIcon extends Component<IProps> {
  renderIcon(): JSX.Element {
    const { connected, spinner, error, touched } = this.props;
    if (error && touched) {
      return <ErrorIcon color="error" />;
    }
    if (spinner) {
      return <CircularProgress color="secondary" css={accordionStyles.spinner} />;
    }
    if (connected) {
      return <Link />;
    }
    return <LinkBroken />;
  }

  render(): JSX.Element {
    return <Fragment>{this.renderIcon()}</Fragment>;
  }
}

export default AccordionIcon;
