import { createReducer } from '@reduxjs/toolkit';
import {
  clearAdministrator,
  clearAdministratorErrors,
  createAdministratorError,
  getAdministratorByIdSuccess,
  getAdministratorsPageableSuccess,
} from './administrator.actions';
import { IAdministratorState } from './administrator.interface';

const initState: IAdministratorState = {
  errors: {},
  administratorsPageable: undefined,
  administrator: undefined,
};

export const administratorReducer = createReducer(initState, (builder) =>
  builder
    .addCase(
      createAdministratorError,
      (
        state,
        {
          payload: {
            error: { status },
          },
        },
      ) => {
        state.errors.email = status === 409 ? 'administrator.errors.duplicate' : undefined;
      },
    )
    .addCase(clearAdministratorErrors, (state) => {
      state.errors = {};
    })
    .addCase(getAdministratorsPageableSuccess, (state, action) => {
      state.administratorsPageable = action.payload;
    })
    .addCase(getAdministratorByIdSuccess, (state, action) => {
      state.administrator = action.payload;
    })
    .addCase(clearAdministrator, (state) => {
      state.administrator = undefined;
    }),
);
