/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Typography } from '@material-ui/core';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CheckboxDiv, CustomStyledCheckbox, FieldDiv } from './CustomInput.styled';

interface IProps extends FieldRenderProps<boolean> {
  label: string;
}

const CustomCheckbox = ({ input, label }: IProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FieldDiv>
      <Typography component="div">
        <Box fontWeight={500} textAlign="start" width="276px" fontSize="14px" color="#333">
          {t(label)}
        </Box>
      </Typography>
      <CheckboxDiv>
        <CustomStyledCheckbox {...input} color={'secondary'} />
      </CheckboxDiv>
    </FieldDiv>
  );
};

export default CustomCheckbox;
