import { css } from '@emotion/core';

export const tableToolbarStyles = {
  root: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    margin-top: 8px;
    justify-content: space-between;
  `,
  input: css`
    background: #fff;
    width: 260px;
  `,
};
