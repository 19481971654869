/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ViewListIcon from '@material-ui/icons/ViewList';
import { IForm } from 'containers/AddUser/AddUserContainer';
import { FormApi } from 'final-form';
import { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IUserState } from 'store/user/user.interface';
import { IAutocompleteField } from 'types/shared';
import { requiredAutocomplete } from 'utils/customValidators';
import { XTMTemplateDTO } from '../../../../../xtm-kontent-app/src/utils/restApplicationClient';
import { AccordionDiv, accordionStyles } from '../Accordion/Accordion.style';
import { customInputStyles } from '../Input/CustomInput.styled';
import DraggableSelect from '../Input/DraggableSelect/DraggableSelect';

interface IProps {
  form: FormApi<IForm>;
  templates: IUserState['templates'];
  customers: Array<{
    label: string;
    value: string;
  }>;
  spinner: boolean;
  initialTemplates?: Array<Array<IAutocompleteField>>;
}

type PropType = IProps & WithTranslation;

export class TemplatesAccordion extends Component<PropType> {
  mapTemplatesToRows = (templates?: Array<XTMTemplateDTO>): Array<{ value: string; label: string }> => {
    return templates?.map(({ name, xtmTemplateId }) => ({ value: xtmTemplateId.toString(), label: name })) || [];
  };

  renderTemplateFields = (): JSX.Element => {
    const { customers, templates, initialTemplates } = this.props;

    return (
      <Fragment>
        {(customers.length > 0 && (
          <FieldArray name="xtmTemplates" initialValue={initialTemplates}>
            {({ fields }): Array<JSX.Element> =>
              fields.map((name, index) => (
                <Field
                  name={name}
                  component={DraggableSelect}
                  validate={requiredAutocomplete}
                  key={index}
                  label={customers?.[index]?.label}
                  options={this.mapTemplatesToRows(templates?.[customers?.[index]?.value])}
                  allowDragAndDrop
                  column
                />
              ))
            }
          </FieldArray>
        )) || (
          <Box display="flex" flexDirection="column" alignItems="center">
            <ViewListIcon fontSize="large" color="disabled" />
            <Typography color="textSecondary" variant="subtitle2">
              Select at least one customer from above list
            </Typography>
          </Box>
        )}
      </Fragment>
    );
  };
  render(): JSX.Element {
    const { t } = this.props;
    return (
      <AccordionDiv>
        <Typography component="div">
          <Box fontWeight={500} textAlign="start" width="276px" marginTop="14px" fontSize="14px" color="#333">
            {t('users.xtm.templates')}
          </Box>
        </Typography>
        <Accordion css={accordionStyles.root}>
          <AccordionSummary css={accordionStyles.summary} expandIcon={<ExpandMoreIcon />}>
            <span css={customInputStyles.label}>{t('common.selectPlaceholder')}</span>
          </AccordionSummary>
          <AccordionDetails css={accordionStyles.details}>{this.renderTemplateFields()}</AccordionDetails>
        </Accordion>
      </AccordionDiv>
    );
  }
}

export default withTranslation()(TemplatesAccordion);
