import { createReducer } from '@reduxjs/toolkit';
import {
  setConnectDataSpinner,
  setConnectToCMSSpinner,
  setConnectToXtmSpinner,
  setCustomerSpinner,
  setDefaultUserLanguageSpinner,
  setFetchSpinner,
  setTemplateSpinner,
  setProjectSpinner,
  setKenticoDataSpinner,
  setKenticoConnectSpinner,
} from './spinner.actions';
import { ISpinnerState } from './spinner.interface';

const initState: ISpinnerState = {
  fetchSpinner: false,
  templateSpinner: false,
  customerSpinner: false,
  connectDataSpinner: false,
  connectToXtmSpinner: false,
  connectToCMSSpinner: false,
  defaultUserLanguagesSpinner: false,
  projectSpinner: false,
  kenticoDataSpinner: false,
  kenticoConnectSpinner: false,
};

export const spinnerReducer = createReducer(initState, (builder) =>
  builder
    .addCase(setFetchSpinner, (state, action) => {
      state.fetchSpinner = action.payload;
    })
    .addCase(setKenticoDataSpinner, (state, action) => {
      state.kenticoDataSpinner = action.payload;
    })
    .addCase(setKenticoConnectSpinner, (state, action) => {
      state.kenticoConnectSpinner = action.payload;
    })
    .addCase(setTemplateSpinner, (state, action) => {
      state.templateSpinner = action.payload;
    })
    .addCase(setCustomerSpinner, (state, action) => {
      state.customerSpinner = action.payload;
    })
    .addCase(setConnectDataSpinner, (state, action) => {
      state.connectDataSpinner = action.payload;
    })
    .addCase(setConnectToXtmSpinner, (state, action) => {
      state.connectToXtmSpinner = action.payload;
    })
    .addCase(setConnectToCMSSpinner, (state, action) => {
      state.connectToCMSSpinner = action.payload;
    })
    .addCase(setDefaultUserLanguageSpinner, (state, action) => {
      state.defaultUserLanguagesSpinner = action.payload;
    })
    .addCase(setProjectSpinner, (state, action) => {
      state.projectSpinner = action.payload;
    }),
);
