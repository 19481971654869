import AddAdministratorContainer from 'containers/AddAdministrator/AddAdministratorContainer';
import AddClientContainer from 'containers/AddClientContainer/AddClientContainer';
import AddUserContainer from 'containers/AddUser/AddUserContainer';
import AdministratorsContainer from 'containers/Administrators/AdministratorsContainer';
import ClientsContainer from 'containers/Clients/ClientsContainer';
import ProjectFormContainer from 'containers/ProjectFormContainer/ProjectFormContainer';
import ProjectsContainer from 'containers/Projects/ProjectsContainer';
import UsersContainer from 'containers/Users/UsersContainer';
import { Roles } from 'enums/roles';
import { IRoute } from 'types/route';

export const mainRoutes: IRoute[] = [
  {
    pathname: '/clients',
    component: ClientsContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
    navbar: true,
    routeTextKey: 'common.clients',
  },
  {
    pathname: '/administrators',
    component: AdministratorsContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
    navbar: true,
    routeTextKey: 'common.administrators',
  },
  {
    pathname: '/users',
    component: UsersContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
    navbar: true,
    routeTextKey: 'common.users',
  },
  {
    pathname: '/projects',
    component: ProjectsContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
    navbar: true,
    routeTextKey: 'common.kenticoProjects',
  },
  {
    pathname: '/project/add',
    component: ProjectFormContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM, Roles.ADMIN_CLIENT],
  },
  {
    pathname: '/project/edit/:id',
    component: ProjectFormContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM, Roles.ADMIN_CLIENT],
  },
  {
    pathname: '/client/add',
    component: AddClientContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
  },
  {
    pathname: '/client/edit/:id',
    component: AddClientContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
  },
  {
    pathname: '/administrator/add',
    component: AddAdministratorContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
  },
  {
    pathname: '/administrator/edit/:id',
    component: AddAdministratorContainer,
    exact: true,
    roles: [Roles.ADMIN_XTM],
  },
  {
    pathname: '/user/add',
    component: AddUserContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
  },
  {
    pathname: '/user/:type/:id',
    component: AddUserContainer,
    exact: true,
    roles: [Roles.ADMIN_CLIENT, Roles.ADMIN_XTM],
  },
];
