import styled from '@emotion/styled';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldDiv } from '../Input/CustomInput.styled';

interface IProps {
  createdAt: Date;
  modifiedAt: Date;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #333;
`;

const Date = styled.p`
  font-weight: 400;
  width: 100%;
  font-size: 14px;
`;

export const EditData = (props: IProps): JSX.Element => {
  const { createdAt, modifiedAt } = props;
  const { t } = useTranslation();
  return (
    <Container>
      <FieldDiv>
        <Typography component="div">
          <Box fontWeight={500} width="276px" fontSize="14px">
            {t('common.creationDate')}
          </Box>
        </Typography>
        <Date>{moment(createdAt).format('DD.MM.YYYY HH:MM')}</Date>
      </FieldDiv>
      <FieldDiv>
        <Typography component="div">
          <Box fontWeight={500} width="276px" fontSize="14px">
            {t('common.modificationDate')}
          </Box>
        </Typography>
        <Date>{moment(modifiedAt).format('DD.MM.YYYY HH:MM')}</Date>
      </FieldDiv>
    </Container>
  );
};
