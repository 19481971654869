import store from 'store';
import { getContentTypes, getWebhooks, getWorkflowSteps } from 'store/project/project.actions';
import { getUsersByClientId } from 'store/user/user.actions';

export enum ProjecValidationErrorEnum {
  CONTENT_TYPES = 'contentTypes',
  WORKFLOWS = 'workflows',
  WEBHOOKS = 'webhooks',
  USERS = 'users',
}

/**
 * Fetch new project fields data based on error message
 */
export function checkProjectValidation(errorArguments: string): void {
  const errorTypes = errorArguments?.split(',');

  // eslint-disable-next-line
  errorTypes?.map((argument) => {
    switch (argument) {
      case ProjecValidationErrorEnum.CONTENT_TYPES: {
        store.dispatch(getContentTypes());
        break;
      }
      case ProjecValidationErrorEnum.WORKFLOWS: {
        store.dispatch(getWorkflowSteps());
        break;
      }
      case ProjecValidationErrorEnum.WEBHOOKS: {
        store.dispatch(getWebhooks());
        break;
      }
      case ProjecValidationErrorEnum.USERS: {
        store.dispatch(getUsersByClientId(store.getState().project.selectedClientId ?? ''));
        break;
      }
    }
  });
}
