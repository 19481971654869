/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, CircularProgress } from '@material-ui/core';
import FormDiv from 'components/Form/Div/FormDiv';
import FormTitle from 'components/Form/Title/FormTitle';
import { ValidationErrors } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import { loginContainerStyles } from '../../containers/Login/LoginContainer.style';
import { changePassword } from '../../store/user/user.actions';
import { comparePass, composeValidators, password, required } from '../../utils/customValidators';
import { ChangePasswordDTO } from '../../utils/restApplicationClientTypeOverrides';
import CustomField from '../Form/Input/CustomField';

interface IResetFormValues {
  newPassword: string;
  confirmPassword: string;
}

interface IResetFormProps {
  token: string | null;
}

export const ChangePasswordForm = (props: IResetFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fetchSpinner = useSelector((state: AppState) => getFetchSpinnerSelector(state));
  const onSubmit = ({ newPassword }: IResetFormValues): void => {
    if (props.token) {
      dispatch(
        changePassword({
          newPassword,
          resetPasswordToken: props.token,
        } as ChangePasswordDTO),
      );
    }
  };

  return (
    <FormDiv width={500}>
      <FormTitle text="auth.password.reset" />
      <Form
        onSubmit={onSubmit}
        validate={(values): ValidationErrors | undefined => comparePass(values)}
        render={({ handleSubmit, submitting }): JSX.Element => (
          <form onSubmit={handleSubmit}>
            <Field
              name="newPassword"
              component={CustomField}
              validate={composeValidators([required, password])}
              label="auth.password.new"
              textFieldProps={{ type: 'password' }}
              column
            />
            <Field
              name="confirmPassword"
              component={CustomField}
              validate={composeValidators([required, password])}
              label="auth.password.confirm"
              textFieldProps={{ type: 'password' }}
              column
            />
            <Button
              variant="contained"
              color="secondary"
              disabled={submitting || fetchSpinner}
              type="submit"
              css={loginContainerStyles.button}
            >
              {fetchSpinner ? <CircularProgress size={24} color="secondary" /> : t('auth.password.change')}
            </Button>
          </form>
        )}
      />
    </FormDiv>
  );
};
