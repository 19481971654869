/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PayloadAction } from '@reduxjs/toolkit';
import { customInputStyles } from 'components/Form/Input/CustomInput.styled';
import { createForm, FormApi, Unsubscribe } from 'final-form';
import Kentico from 'icons/Kentico';
import { Component, Fragment } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { subscribeActionAfter } from 'redux-subscribe-action';
import { AppDispatch, AppState } from 'store';
import { clearProjectFormAll, clearProjectErrors, connectToKentico, getApiKey } from 'store/project/project.actions';
import { ProjectActionTypes } from 'store/project/project.actions.types';
import { IConntectedProject, IProjectForm } from 'store/project/project.interface';
import { getProjectErrorsSelector, getProjectFormSelector } from 'store/project/project.selectors';
import { getKenticoConnectSpinnerSelector } from 'store/spinner/spinner.selectors';
import { composeValidators, fetchValidator, required } from 'utils/customValidators';
import { ApiKeysDTO } from 'utils/restApplicationClient';
import CustomField from '../../Input/CustomField';
import { AccordionButton, AccordionDiv, accordionStyles } from '../Accordion.style';
import AccordionIcon from '../AccordionIcon';

interface IDispatchProps {
  connectToKentico: (payload: IConntectedProject) => AppDispatch;
  clearProjectErrors: () => AppDispatch;
  clearProjectFormAll: () => AppDispatch;
  getApiKey: (payload: string) => AppDispatch;
}

interface IState {
  formValues: IConntectedProject;
  isConnected: boolean;
}

interface IStateProps {
  kenticoSpinner: boolean;
  errors: { [key: string]: string | undefined };
  clientForm: IProjectForm;
}

interface IProps {
  kontentProjectId: string;
  onConnect: (kontentProjectId: string | undefined) => void;
  isProjectIdEditable: boolean;
}

type PropType = FieldRenderProps<unknown, HTMLElement> & WithTranslation & IProps & IDispatchProps & IStateProps;

export class ProjectConnectAccordion extends Component<PropType, IState> {
  unsubscribe: (() => void)[] = [];
  unsubscribeForm: Unsubscribe;

  constructor(props: PropType) {
    super(props);
    this.state = {
      formValues: {
        kontentProjectId: '',
        managementApiKey: '',
        deliveryApiKey: '',
      },
      isConnected: false,
    };
    this.form = createForm({
      onSubmit: this.onSubmit,
    });
    this.unsubscribeForm = this.form.subscribe(
      () => {
        const { errors } = this.props;

        if (errors['kenticoConnect']) {
          this.props.clearProjectErrors();
        }
      },
      { values: true },
    );
  }

  componentDidMount(): void {
    const { connectToKentico } = this.props;
    const connectSubscription = subscribeActionAfter(ProjectActionTypes.ConnectToKenticoSuccess, () => {
      const kontentProjectId = this.form.getFieldState('kontentProjectId')?.value || '';

      this.setConnection(kontentProjectId);
    });
    const connectErrorSubscription = subscribeActionAfter(ProjectActionTypes.ConnectToKenticoError, () => {
      this.setConnection();
    });
    const apiKeySubscription = subscribeActionAfter(ProjectActionTypes.GetApiKeySuccess, (action) => {
      const { kontentProjectId } = this.props;
      const { managementApiKey, deliveryApiKey } = (action as PayloadAction<ApiKeysDTO>).payload;
      const connectedData: IConntectedProject = { kontentProjectId, managementApiKey, deliveryApiKey };

      this.setState({ formValues: connectedData });
      connectToKentico(connectedData);
    });

    this.unsubscribe.push(connectSubscription, connectErrorSubscription, apiKeySubscription);
  }

  componentDidUpdate(previousProps: PropType): void {
    const { kontentProjectId, getApiKey, errors } = this.props;

    if (!!kontentProjectId && previousProps.kontentProjectId === undefined) {
      getApiKey(kontentProjectId);
    }

    if (errors['kontentProjectId'] && errors['kontentProjectId'] !== previousProps.errors['kontentProjectId']) {
      this.setConnection();
    }
  }

  componentWillUnmount(): void {
    this.unsubscribeForm();
    this.unsubscribe.forEach((unsubscribe) => unsubscribe());
  }

  setConnection(value?: string): void {
    const {
      input: { onChange },
      onConnect,
    } = this.props;

    this.setState({ isConnected: !!value });
    onChange(value);
    onConnect(value);
  }

  form: FormApi<IConntectedProject, Partial<Record<string, unknown>>>;

  onSubmit = (values: IConntectedProject): void => {
    this.setState({ formValues: values });
    this.props.input.onChange(values.kontentProjectId);
    this.props.onConnect(values.kontentProjectId);
    this.props.connectToKentico(values);
  };

  render(): JSX.Element {
    const { t, meta, kenticoSpinner, errors, isProjectIdEditable } = this.props;
    const { isConnected, formValues } = this.state;

    return (
      <AccordionDiv>
        <Typography component="div">
          <Box fontWeight={500} textAlign="start" width="276px" marginTop="14px" fontSize="14px" color="#333">
            {t('projects.connectProject')}
          </Box>
        </Typography>
        <Accordion css={accordionStyles.root}>
          <AccordionSummary css={accordionStyles.summary} expandIcon={<ExpandMoreIcon />}>
            <AccordionIcon
              error={!!meta.error}
              touched={meta.touched}
              connected={isConnected}
              spinner={kenticoSpinner}
            />
            <span css={customInputStyles.label}>{isConnected ? t('users.connected') : t('users.disconnected')}</span>
          </AccordionSummary>
          <AccordionDetails css={accordionStyles.details}>
            {errors['kenticoConnect'] && (
              <span css={accordionStyles.accordionErrorText}>{t(errors['kenticoConnect'])}</span>
            )}
            <Form
              form={this.form}
              onSubmit={this.onSubmit}
              subscription={{ pristine: true, submitting: true }}
              initialValues={formValues as Partial<IConntectedProject>}
              render={({ handleSubmit, submitting, pristine }): JSX.Element => (
                <Fragment>
                  <Field
                    name="kontentProjectId"
                    component={CustomField}
                    validate={composeValidators([
                      required,
                      fetchValidator(errors['kontentProjectId'], formValues?.kontentProjectId),
                    ])}
                    key={
                      errors['kontentProjectId']
                        ? `kontentProjectIdError${formValues?.kontentProjectId}`
                        : 'kontentProjectId'
                    }
                    label="projects.kontentProjectId"
                    column
                    disabled={isProjectIdEditable === false}
                    tooltip={isProjectIdEditable === false && t('projects.errors.notEditableProjectId')}
                  />
                  <Field
                    name="managementApiKey"
                    component={CustomField}
                    validate={required}
                    label="projects.managementApiKey"
                    column
                  />
                  <Field
                    name="deliveryApiKey"
                    component={CustomField}
                    validate={required}
                    label="projects.deliveryApiKey"
                    column
                  />

                  <AccordionButton
                    variant="contained"
                    color="secondary"
                    disabled={submitting || kenticoSpinner || pristine}
                    onClick={handleSubmit}
                    fullWidth
                  >
                    {kenticoSpinner ? (
                      <CircularProgress color="secondary" size={24} />
                    ) : (
                      <Fragment>
                        <Kentico />
                        {t('projects.connectProject')}
                      </Fragment>
                    )}
                  </AccordionButton>
                </Fragment>
              )}
            />
          </AccordionDetails>
        </Accordion>
      </AccordionDiv>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  connectToKentico: (payload: IConntectedProject): AppDispatch => dispatch(connectToKentico(payload)),
  clearProjectErrors: (): AppDispatch => dispatch(clearProjectErrors()),
  getApiKey: (payload: string): AppDispatch => dispatch(getApiKey(payload)),
  clearProjectFormAll: (): AppDispatch => dispatch(clearProjectFormAll()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  kenticoSpinner: getKenticoConnectSpinnerSelector(state),
  errors: getProjectErrorsSelector(state),
  clientForm: getProjectFormSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectConnectAccordion));
