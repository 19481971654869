/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box } from '@material-ui/core';
import { IForm } from 'containers/AddUser/AddUserContainer';
import { FormApi } from 'final-form';
import { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { GetProjectsByClientId } from 'store/project/project.actions';
import { getClientProjectsSelector } from 'store/project/project.selectors';
import {
  getCustomerSpinnerSelector,
  getProjectSpinnerSelector,
  getTemplateSpinnerSelector,
} from 'store/spinner/spinner.selectors';
import { clearXtmTemplates, xtmGetTemplates } from 'store/user/user.actions';
import { IUserState, XTMGetTemplatesDTO } from 'store/user/user.interface';
import {
  getDefaultUserLanguagesSelector,
  getXtmCustomersSelector,
  getXtmTemplatesSelector,
} from 'store/user/user.selectors';
import { IAutocompleteField } from 'types/shared';
import { composeValidators, isAllowedPrefix, isNumber, required, requiredAutocomplete } from 'utils/customValidators';
import { KontentProjectOverviewDTO, UserDTO, XTMCustomerInfoDTO, XTMLanguageDTO } from 'utils/restApplicationClient';
import { EditData } from '../CilentData/EditData';
import CustomCheckbox from '../Input/CustomCheckbox';
import CustomField from '../Input/CustomField';
import DraggableSelect from '../Input/DraggableSelect/DraggableSelect';
import { ConnectedFieldsCheckboxGroup } from './ConnectedFields.styled';
import {
  CustomLanguageMappingContainer,
  CustomLanguageMappingLegend,
} from './LanguageMapping/LanguageMappingContainer.styled';
import TemplatesAccordion from './TemplatesAccordion';

interface IProps {
  native?: boolean;
  authorizationId: string;
  user?: UserDTO;
  form: FormApi<IForm>;
  initialCustomers?: Array<IAutocompleteField>;
  initialTemplates?: Array<Array<IAutocompleteField>>;
}

interface IState {
  selectedCustomers?: Array<IAutocompleteField>;
  initialProjects?: Array<IAutocompleteField>;
}

interface IDispatchProps {
  getTemplates: (payload: XTMGetTemplatesDTO) => AppDispatch;
  clearTemplates: () => AppDispatch;
  getProjectsByClientId: (payload: { clientUUID: string }) => AppDispatch;
}

interface IStateProps {
  customers?: XTMCustomerInfoDTO[];
  templates: IUserState['templates'];
  customerSpinner: boolean;
  templateSpinner: boolean;
  defaultLanguages?: XTMLanguageDTO[];
  clientProjects: KontentProjectOverviewDTO[];
  projectsSpinner: boolean;
}

type PropType = IProps & IDispatchProps & IStateProps & WithTranslation;

export class ConnectedUserFields extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      selectedCustomers: [],
      initialProjects: undefined,
    };
  }

  componentDidMount(): void {
    const { form, getProjectsByClientId } = this.props;
    const { clientUUID } = form.getState().values;
    if (clientUUID) {
      getProjectsByClientId({ clientUUID });
    }
  }

  componentDidUpdate(previousProps: PropType): void {
    const { user, clientProjects } = this.props;

    if (user !== previousProps.user || clientProjects !== previousProps.clientProjects) {
      this.mapAssignedProjects();
    }
  }

  handleCustomerChange = (value: Array<IAutocompleteField> | null): void => {
    const { getTemplates, authorizationId } = this.props;

    this.setState({
      selectedCustomers: value || [],
    });
    if (value) {
      getTemplates({ xtmAuthId: authorizationId, xtmCustomerIds: value.map((customer) => Number(customer.value)) });
    }
  };

  handleOnCustomerChange = (
    selectedCustomers: Array<IAutocompleteField> | null,
    value: Array<IAutocompleteField> | null
  ): void => {
    const {
      form: { mutators },
    } = this.props;

    if (selectedCustomers && selectedCustomers.length > 0 && value && value.length > 0) {
      const valueIds = value.map(({ value }) => value);
      const removedIndex = selectedCustomers.findIndex(({ value }) => !valueIds.includes(value));

      if (removedIndex >= 0) {
        mutators.remove('xtmTemplates', removedIndex);
        return;
      }
    } else if (value && value.length === 0) {
      mutators.remove('xtmTemplates', 0);
      return;
    }
    mutators.push('xtmTemplates', []);
  };

  handleChipDelete = (index: number): void => {
    const {
      form: { mutators },
    } = this.props;

    mutators.remove('xtmTemplates', index);
  };

  handleSelectAllCustomers = (
    selectedCustomers: Array<IAutocompleteField>,
    options: Array<IAutocompleteField>
  ): void => {
    const { form } = this.props;
    const previousTemplates = form.getState().values.xtmTemplates;

    if (selectedCustomers) {
      const previousCustomers = selectedCustomers?.map(({ value }) => value) || [];

      selectedCustomers.forEach((customer, index) => {
        form.mutators.update('xtmTemplates', index, []);
      });

      form.mutators.concat('xtmTemplates', new Array<[]>(options.length - previousCustomers.length));

      previousCustomers.forEach((previousCustomerId, index) => {
        const templateIndex = options.findIndex((option) => option.value === previousCustomerId);

        if (templateIndex >= 0) {
          form.mutators.update('xtmTemplates', templateIndex, previousTemplates[index]);
        }
      });
    } else {
      form.mutators.concat('xtmTemplates', new Array<[]>(options.length));
    }
  };

  handleClearAllCustomers = (selectedCustomers: Array<IAutocompleteField>): void => {
    const {
      form: { mutators },
    } = this.props;
    if (selectedCustomers) {
      const previousCustomerIndexes = selectedCustomers.map((customer, index) => index);

      mutators.removeBatch('xtmTemplates', previousCustomerIndexes);
    }
  };

  handleDragEnd = (destination: number, source: number): void => {
    const {
      form: { mutators },
    } = this.props;

    mutators.swap('xtmTemplates', source, destination);
  };

  mapCustomersToRows = (customers?: Array<XTMCustomerInfoDTO>): Array<{ value: string; label: string }> => {
    return customers?.map((customer) => ({ value: customer.xtmCustomerId.toString(), label: customer.name })) || [];
  };

  mapAssignedProjects = (): void => {
    const { user, clientProjects } = this.props;

    if (user && clientProjects.length > 0) {
      const { assignedProjectIds } = user;

      const initialProjects = assignedProjectIds
        .map((assignedProjectId) => {
          const project = clientProjects.find((clientProject) => clientProject.id === assignedProjectId);

          if (project) {
            return {
              label: project.kenticoProjectName,
              value: assignedProjectId,
            };
          }
          return undefined;
        })
        .filter((value) => value) as Array<IAutocompleteField>;

      this.setState({
        initialProjects,
      });

      return;
    }
    this.setState({
      initialProjects: undefined,
    });
  };

  render(): JSX.Element {
    const {
      user,
      form,
      clientProjects,
      customers,
      templateSpinner,
      customerSpinner,
      templates,
      initialCustomers,
      initialTemplates,
      projectsSpinner,
      t,
    } = this.props;
    const { selectedCustomers, initialProjects } = this.state;

    return (
      <Fragment>
        <OnChange name="xtmCustomers">
          {(value: Array<IAutocompleteField>): void => {
            this.setState({
              selectedCustomers: value || [],
            });
          }}
        </OnChange>
        <Field
          name="xtmCustomers"
          component={DraggableSelect}
          initialValue={initialCustomers}
          validate={requiredAutocomplete}
          label="users.xtm.customers"
          options={this.mapCustomersToRows(customers)}
          onChange={this.handleCustomerChange}
          onHandleChange={this.handleOnCustomerChange}
          handleChipDelete={this.handleChipDelete}
          handleSelectAll={this.handleSelectAllCustomers}
          handleClearAll={this.handleClearAllCustomers}
          handleDragEnd={this.handleDragEnd}
          spinner={customerSpinner}
          disabled={templateSpinner}
          mutators={form.mutators}
          allowDragAndDrop
        />
        <TemplatesAccordion
          form={form}
          customers={selectedCustomers || []}
          templates={templates}
          spinner={templateSpinner}
          initialTemplates={initialTemplates}
        />
        <Field
          name="daysToDefaultDueDate"
          component={CustomField}
          validate={composeValidators([isNumber, required])}
          label="users.xtm.dueDateDaysAhead"
          textFieldProps={{ type: 'number' }}
          width="50%"
          disableAutocomplete
        />
        <ConnectedFieldsCheckboxGroup>
          <Field
            name="allowDueDateChange"
            type="checkbox"
            label="users.xtm.allowDueDateChange"
            component={CustomCheckbox}
            defaultValue={false}
          />
          <CustomLanguageMappingContainer>
            <CustomLanguageMappingLegend>{t('users.xtm.emailNotifications')}</CustomLanguageMappingLegend>
            <Field
              name="notifyWhenXTMWorkflowChange"
              type="checkbox"
              label="users.xtm.notifyWhenXTMWorkflowChange"
              component={CustomCheckbox}
              defaultValue={false}
            />
            <Box marginY="12px"></Box>
            <Field
              name="notifyWhenTranslationImported"
              type="checkbox"
              label="users.xtm.notifyWhenTranslationImported"
              component={CustomCheckbox}
              defaultValue={false}
            />
          </CustomLanguageMappingContainer>
        </ConnectedFieldsCheckboxGroup>
        <Field
          name="assignedProjectIds"
          initialValue={initialProjects}
          component={DraggableSelect}
          label="users.xtm.kenticoProject"
          options={clientProjects?.map(({ id, kenticoProjectName }) => ({ label: kenticoProjectName, value: id }))}
          spinner={projectsSpinner}
        />
        <Field
          name="xtmProjectNamePrefix"
          component={CustomField}
          validate={composeValidators([required, isAllowedPrefix])}
          label="users.xtm.projectNamePrefix"
        />
        <Field
          name="daysAfterRemoveArchivedOrDeletedFiles"
          component={CustomField}
          validate={composeValidators([isNumber])}
          label="users.xtm.daysAfterRemoveArchivedOrDeletedFiles"
          disableAutocomplete
          textFieldProps={{ type: 'number' }}
        />
        <Field
          name="daysAfterRemoveCancelledJobs"
          component={CustomField}
          validate={composeValidators([isNumber])}
          label="users.xtm.daysAfterRemoveCancelledJobs"
          disableAutocomplete
          textFieldProps={{ type: 'number' }}
        />
        {user && <EditData createdAt={user.createdAt} modifiedAt={user.modifiedAt} />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  customers: getXtmCustomersSelector(state),
  templates: getXtmTemplatesSelector(state),
  customerSpinner: getCustomerSpinnerSelector(state),
  templateSpinner: getTemplateSpinnerSelector(state),
  defaultLanguages: getDefaultUserLanguagesSelector(state),
  clientProjects: getClientProjectsSelector(state),
  projectsSpinner: getProjectSpinnerSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  getTemplates: (payload: XTMGetTemplatesDTO): AppDispatch => dispatch(xtmGetTemplates(payload)),
  clearTemplates: (): AppDispatch => dispatch(clearXtmTemplates()),
  getProjectsByClientId: (payload: { clientUUID: string }): AppDispatch => dispatch(GetProjectsByClientId(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConnectedUserFields));
