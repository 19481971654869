import React from 'react';

export default function Help(): JSX.Element {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9531 0.273438C11.6641 0.34375 9.10938 0.601562 7.53906 1.5625C7.42188 1.63281 7.35156 1.75 7.35156 1.86719V10.3984C7.35156 10.6797 7.65625 10.8438 7.91406 10.7266C9.53125 9.90625 11.875 9.67188 13.0234 9.625C13.4219 9.60156 13.7266 9.27344 13.7266 8.89844V0.976562C13.75 0.578125 13.375 0.25 12.9531 0.273438ZM6.4375 1.5625C4.86719 0.601562 2.3125 0.34375 1.02344 0.273438C0.601562 0.25 0.25 0.578125 0.25 0.976562V8.89844C0.25 9.27344 0.554688 9.60156 0.953125 9.625C2.10156 9.67188 4.44531 9.90625 6.0625 10.7266C6.32031 10.8438 6.625 10.6797 6.625 10.3984V1.86719C6.625 1.75 6.55469 1.63281 6.4375 1.5625Z"
        fill="white"
      />
    </svg>
  );
}
