import { createAction } from '@reduxjs/toolkit';
import { ErrorPayload } from 'store/error/error.epic';
import { IEditClient, IPaginationOptions } from 'types/api';
import { ClientDTO, CreateClientDTO, Page } from 'utils/restApplicationClient';
import { ClientActionTypes } from './client.actions.types';

export const getAllClients = createAction(ClientActionTypes.GetAllClients);

export const getAllClientsError = createAction<ErrorPayload>(ClientActionTypes.GetAllClientsError);
export const getAllClientsSuccess = createAction<ClientDTO[]>(ClientActionTypes.GetAllClientsSuccess);
export const createClient = createAction<CreateClientDTO>(ClientActionTypes.CreateClient);
export const createClientSuccess = createAction(ClientActionTypes.CreateClientSuccess);
export const createClientError = createAction<ErrorPayload>(ClientActionTypes.CreateClientError);

export const getClientsPageable = createAction<IPaginationOptions>(ClientActionTypes.GetClientsPageable);
export const getClientsPageableSuccess = createAction<Page<ClientDTO>>(ClientActionTypes.GetClientsPageableSuccess);
export const getClientsPageableError = createAction<ErrorPayload>(ClientActionTypes.GetClientsPageableError);
export const editClient = createAction<IEditClient>(ClientActionTypes.EditClient);
export const editClientSuccess = createAction(ClientActionTypes.EditClientSuccess);
export const editClientError = createAction<ErrorPayload>(ClientActionTypes.EditClientError);

export const getClientById = createAction<string>(ClientActionTypes.GetClientById);
export const getClientByIdSuccess = createAction<ClientDTO>(ClientActionTypes.GetClientByIdSuccess);
export const getClientByIdError = createAction<ErrorPayload>(ClientActionTypes.GetClientByIdError);

export const clearClient = createAction(ClientActionTypes.ClearClient);

export const clearClientErrors = createAction(ClientActionTypes.ClearClientErrors);
