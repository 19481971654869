import { createReducer } from '@reduxjs/toolkit';
import {
  clearClient,
  clearClientErrors,
  createClientError,
  getAllClientsSuccess,
  getClientByIdSuccess,
  getClientsPageableSuccess,
} from './client.actions';
import { IClientState } from './client.interface';

const initState: IClientState = {
  clients: [],
  errors: {},
  clientsPageable: undefined,
  client: undefined,
};

export const clientReducer = createReducer(initState, (builder) =>
  builder
    .addCase(getAllClientsSuccess, (state, action) => {
      state.clients = action.payload;
    })
    .addCase(
      createClientError,
      (
        state,
        {
          payload: {
            error: { status },
          },
        },
      ) => {
        state.errors.clientName = status === 409 ? 'client.errors.duplicate' : undefined;
      },
    )
    .addCase(getClientsPageableSuccess, (state, action) => {
      state.clientsPageable = action.payload;
    })
    .addCase(clearClient, (state) => {
      state.client = undefined;
    })
    .addCase(getClientByIdSuccess, (state, action) => {
      state.client = action.payload;
    })
    .addCase(clearClientErrors, (state) => {
      state.errors = {};
    }),
);
