/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Chip, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { Autocomplete, AutocompleteGetTagProps } from '@material-ui/lab';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import React, { ComponentType, Fragment, ReactNode } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { FieldRenderProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IAutocompleteField } from 'types/shared';
import {
  AutocompleteChipDefaultSpan,
  AutocompleteTextField,
  CustomAutocompleteTypography,
  CustomDraggableContainer,
  CustomEndAdornment,
  InputDiv,
} from './CustomInput.styled';

interface IProps extends FieldRenderProps<IAutocompleteField | Array<IAutocompleteField>> {
  options: Array<IAutocompleteField>;
  onChange?: (event: React.ChangeEvent<{}>, value: IAutocompleteField | Array<IAutocompleteField> | null) => void;
  multi?: boolean;
  label: string;
  noOptionsText?: string;
  error?: string;
  spinner?: boolean;
  disabled?: boolean;
  column?: boolean;
  allowDragAndDrop?: boolean;
  placeholder?: string;
}

type PropType = IProps & WithTranslation;

export class CustomAutocomplete extends React.Component<PropType> {
  handleChange = (event: React.ChangeEvent<{}>, value: IAutocompleteField | Array<IAutocompleteField> | null): void => {
    const { onChange, input } = this.props;

    if (onChange) {
      onChange(event, value);
    }
    input.onChange(value);
  };

  onDragEnd: OnDragEndResponder = ({ destination, source }) => {
    const { input } = this.props;

    if (!destination) {
      return;
    }

    const filteredData = input.value as IAutocompleteField<string>[];
    const [removed] = filteredData.splice(source.index, 1);

    filteredData.splice(destination.index, 0, removed);
    input.onChange(filteredData);
  };

  renderTags = (
    value: {
      label: string;
      value: string;
    }[],
    getTagProps: AutocompleteGetTagProps,
  ): JSX.Element => {
    return (
      <Fragment>
        {value.map((tag, index) => (
          <Chip
            size="small"
            key="index"
            {...getTagProps({ index })}
            deleteIcon={<CloseIcon />}
            icon={<CloseIcon />}
            label={tag.label}
          />
        ))}
      </Fragment>
    );
  };

  renderCustomTags = (
    value: {
      label: string;
      value: string;
    }[],
    getTagProps: AutocompleteGetTagProps,
  ): JSX.Element => {
    const { t } = this.props;

    return (
      <Fragment>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided): JSX.Element => (
            <CustomDraggableContainer ref={provided.innerRef}>
              {value.map((tag, index) => (
                <Draggable key={tag.value} draggableId={tag.value} index={index}>
                  {(provided): JSX.Element => (
                    <Chip
                      size="small"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      {...getTagProps({ index })}
                      deleteIcon={<CloseIcon />}
                      icon={<CloseIcon />}
                      label={
                        <div>
                          {tag.label}
                          {index === 0 ? (
                            <AutocompleteChipDefaultSpan>{`(${t('common.default')})`}</AutocompleteChipDefaultSpan>
                          ) : (
                            ''
                          )}
                        </div>
                      }
                    />
                  )}
                </Draggable>
              ))}
            </CustomDraggableContainer>
          )}
        </Droppable>
      </Fragment>
    );
  };

  renderAdornment = (endAdornment: ReactNode): JSX.Element => {
    const { spinner, error, t } = this.props;

    if (spinner) {
      return (
        <CustomEndAdornment>
          <CircularProgress size={12} />
        </CustomEndAdornment>
      );
    }
    return (
      <Fragment>
        {error && (
          <Tooltip title={<Fragment>{t(error)}</Fragment>}>
            <ErrorIcon color="error" />
          </Tooltip>
        )}
        {endAdornment}
      </Fragment>
    );
  };

  render(): JSX.Element {
    const {
      options,
      multi,
      input,
      label,
      t,
      meta: { error, touched },
      spinner,
      disabled,
      noOptionsText,
      column,
      allowDragAndDrop,
      placeholder,
    } = this.props;

    return (
      <InputDiv column={column}>
        {/* column as boolean throws type exception for some reason */}
        <CustomAutocompleteTypography column={column && 'true'} component="div">
          <Box fontWeight={500} width="276px" fontSize="14px" color="#333">
            {t(label)}
          </Box>
        </CustomAutocompleteTypography>
        <Box width={column ? '100%' : '400px'}>
          <Autocomplete
            fullWidth
            disabled={disabled || spinner}
            multiple={multi}
            options={options}
            noOptionsText={!options.length ? noOptionsText : undefined}
            getOptionLabel={(option): string => (option.label ? option.label : '')}
            onChange={this.handleChange}
            getOptionSelected={(option, value): boolean => value.label === option.label || !multi}
            value={input.value ? input.value : []}
            renderInput={(parameters): JSX.Element => (
              <DragDropContext onDragEnd={this.onDragEnd}>
                <AutocompleteTextField
                  {...parameters}
                  draggable={allowDragAndDrop}
                  margin="dense"
                  inputProps={{
                    ...parameters.inputProps,
                  }}
                  variant="outlined"
                  error={Boolean(error && touched)}
                  InputProps={{
                    ...parameters.InputProps,
                    endAdornment: this.renderAdornment(parameters.InputProps.endAdornment),
                  }}
                  placeholder={placeholder}
                />
              </DragDropContext>
            )}
            renderTags={allowDragAndDrop ? this.renderCustomTags : this.renderTags}
            renderOption={(option): JSX.Element => <CustomTooltip text={option.label} width={400} maxLines={1} />}
            size="small"
          />
          {error && touched && (
            <Typography variant="caption" color="error" component="div">
              <Box marginTop="4px">{t(error)}</Box>
            </Typography>
          )}
        </Box>
      </InputDiv>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withTranslation()(CustomAutocomplete) as ComponentType<FieldRenderProps<any, HTMLElement>>;
