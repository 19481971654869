import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        padding: '2px',
      },
    },
    MuiTablePagination: {
      toolbar: {
        background: '#fff',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 400,
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: '#fff',
      },
      indicator: {
        backgroundColor: '#00A4E4',
        height: '4px',
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '4px',
        marginTop: '8px',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#333333 !important',
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '15px',
        fontWeight: 700,
        background: '#fff',
        padding: '7px 16px',
      },
      body: {
        padding: '3px 16px 4px 16px',
        color: '#333333 !important',
      },
      stickyHeader: {
        backgroundColor: '#fff',
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: '#fff',
        '&.MuiTableRow-hover:hover': {
          backgroundColor: '#ccedfa',
        },
      },
    },
    MuiTableContainer: {
      root: {
        width: 'auto',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '15px',
        opacity: '1 !important',
        minWidth: '80px !important',
        padding: '6px 19px',
      },
    },
    MuiAppBar: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '16px 21px 20px 16px',
        boxShadow: 'none',
        justifyContent: 'space-between',
      },
    },
    MuiTextField: {
      root: {
        background: '#fff',
      },
    },
    MuiSelect: {
      root: {
        background: '#fff',
      },
    },
    MuiInputBase: {
      root: {
        color: '#333',
        fontSize: '14px',
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: '0px',
      },
      inputMarginDense: {
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      root: {
        height: '32px',
      },
      marginDense: {
        padding: '2.815px 0 !important',
      },
    },
  },
  palette: {
    primary: {
      main: '#2e3645',
      contrastText: '#FFFFFF',
      light: '5EB555',
    },
    secondary: {
      main: '#0096eb',
    },
    text: {
      secondary: '#6F8294',
    },
  },
});
