/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { ActionCreatorWithPayload, Dispatch } from '@reduxjs/toolkit';
import { OrderDirection } from 'enums/filter';
import moment from 'moment';
import { ClassAttributes, Component, ComponentType, Fragment } from 'react';
import { withTranslation, WithTranslation, WithTranslationProps } from 'react-i18next';
import { connect, ConnectedComponent } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import { clearPaginationOptions, setPaginationAction, setPaginationOptions } from 'store/table/table.actions';
import { ITablePaginationOptions } from 'store/table/table.interface';
import { getPaginationOptionsSelector } from 'store/table/table.selectors';
import { IDateFields, IPaginationOptions } from 'types/api';
import { parseDateBulk } from 'utils/parseDate';
import { Page } from 'utils/restApplicationClient';
import { customTableStyle } from './CustomTable.style';
import Pagination, { IPaginationProps } from './Pagination';
import TableEmpty from './TableEmpty/TableEmpty';

interface IProps<T> {
  headers: { id: string; label: string }[];
  onEdit: (row: T) => void;
  onDuplicate?: (row: T) => void;
  paginationAction: ActionCreatorWithPayload<IPaginationOptions>;
  pageable?: Page<T>;
  search: string;
  nativeSelect?: boolean;
  fetchSpinnerOverride?: boolean;
  displayActionsLabel?: boolean;
}

interface IState {
  page: number;
  rowsPerPage: number;
  orderBy: string;
  order: OrderDirection;
}

interface IStateProps {
  paginationOptions: ITablePaginationOptions;
  fetchSpinner: boolean;
}

interface IDispatchProps {
  setPaginationOptions: (payload: ITablePaginationOptions) => AppDispatch;
  setPaginationAction: (payload: ActionCreatorWithPayload<IPaginationOptions> | undefined) => AppDispatch;
  clearPaginationOptions: () => AppDispatch;
}

interface IExtend extends IDateFields {
  id: string;
}

export class CustomTable<T extends IExtend> extends Component<
  IProps<T> & WithTranslation & IStateProps & IDispatchProps,
  IState
> {
  componentDidMount(): void {
    this.props.setPaginationAction(this.props.paginationAction);
  }

  componentWillUnmount(): void {
    this.props.setPaginationAction(undefined);
    this.props.clearPaginationOptions();
  }

  onChangePage = (event: React.ChangeEvent<unknown>, page: number): void => {
    this.props.setPaginationOptions({ page: page - 1 });
  };
  onChangeRowsPerPage = ({
    target: { value },
  }: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>): void => {
    this.props.setPaginationOptions({ size: Number(value) });
  };
  onChangeOrder = (property: string): (() => void) => {
    return (): void => {
      const { orderBy, order } = this.props.paginationOptions;
      const isAsc = orderBy === property && order === OrderDirection.ASC;
      this.props.setPaginationOptions({
        order: isAsc ? OrderDirection.DESC : OrderDirection.ASC,
        orderBy: property,
      });
    };
  };
  paginationProps = (): IPaginationProps => {
    const {
      pageable,
      paginationOptions: { page, size },
    } = this.props;
    return {
      onChangePage: this.onChangePage,
      onChangeRowsPerPage: this.onChangeRowsPerPage,
      page: page as number,
      rowsPerPage: size as number,
      count: pageable ? pageable.totalElements : 0,
      nativeSelect: this.props.nativeSelect,
      columns: this.props.headers.length,
    };
  };

  parseColumn = (row: T, key: keyof T): string => {
    if (key === 'name') {
      return String(row['kenticoProjectName' as keyof T]);
    }

    if (key === 'client.clientName') {
      return String(row['clientName' as keyof T]);
    }

    if (key === 'createdAt' || key === 'modifiedAt') {
      return moment(row[key]).format('DD.MM.YYYY HH:MM');
    }

    if (key !== 'status') {
      return String(row[key]);
    }

    const { t } = this.props;

    if (row[key]) {
      return t('common.active');
    }
    return t('common.inactive');
  };

  renderEmpty = (): JSX.Element => {
    const { fetchSpinner, fetchSpinnerOverride } = this.props;
    if (fetchSpinner && !fetchSpinnerOverride) {
      return <CircularProgress color="secondary" size={120} css={customTableStyle.spinner} />;
    }
    return <TableEmpty />;
  };

  render(): JSX.Element {
    const {
      headers,
      pageable,
      onEdit,
      t,
      fetchSpinner,
      fetchSpinnerOverride,
      onDuplicate,
      displayActionsLabel,
    } = this.props;
    const { orderBy, order } = this.props.paginationOptions;
    return (
      <TableContainer component={Paper} css={customTableStyle.root}>
        <Table stickyHeader>
          <TableHead>
            <Pagination {...this.paginationProps()} />
            {Boolean(pageable && pageable.content && pageable.content.length && !fetchSpinner) && (
              <TableRow>
                {headers.map((headCell, index) => (
                  <TableCell key={`header-${index}`} sortDirection={orderBy === headCell.id ? order : false}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      onClick={this.onChangeOrder(headCell.id)}
                      direction={orderBy === headCell.id ? order : OrderDirection.ASC}
                      className={'sortLabel'}
                    >
                      {t(headCell.label)}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>{displayActionsLabel ? t('common.actions') : ''}</TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {(pageable && pageable.content && !pageable.content.length) ||
            !pageable ||
            (fetchSpinner && !fetchSpinnerOverride) ? (
              <TableRow>
                <TableCell colSpan={Object.keys(headers).length + 1} padding="none" css={customTableStyle.spinnerCell}>
                  {this.renderEmpty()}
                </TableCell>
              </TableRow>
            ) : (
              pageable &&
              pageable.content &&
              pageable.content.length &&
              parseDateBulk<T, Page<T>>(pageable).content.map((row, index) => (
                <TableRow key={`row-${index}`} hover style={{ backgroundColor: index % 2 === 0 ? '#EFF3F6' : '#fff' }}>
                  {headers.map((header) => (
                    <TableCell key={`${header.id}-${index}`}>{this.parseColumn(row, header.id as keyof T)}</TableCell>
                  ))}
                  <TableCell align="right">
                    <div css={customTableStyle.iconContainer}>
                      <Tooltip title="Edit">
                        <IconButton onClick={(): void => onEdit(row)} css={customTableStyle.button}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {onDuplicate ? (
                        <Tooltip title={<Fragment> {t('users.duplicate')} </Fragment>}>
                          <IconButton css={customTableStyle.button} onClick={(): void => onDuplicate(row)}>
                            <PersonAddIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <Pagination {...this.paginationProps()} />
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  paginationOptions: getPaginationOptionsSelector(state),
  fetchSpinner: getFetchSpinnerSelector(state),
});
const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setPaginationOptions: (payload: ITablePaginationOptions): AppDispatch => dispatch(setPaginationOptions(payload)),
  setPaginationAction: (payload: ActionCreatorWithPayload<IPaginationOptions> | undefined): AppDispatch =>
    dispatch(setPaginationAction(payload)),
  clearPaginationOptions: (): AppDispatch => dispatch(clearPaginationOptions()),
});
export default function CustomTableContainer<T extends IExtend>(): ConnectedComponent<
  ComponentType<
    Pick<
      IProps<T> & WithTranslation & IStateProps & IDispatchProps,
      | 'paginationOptions'
      | 'pageable'
      | 'headers'
      | 'onEdit'
      | 'paginationAction'
      | 'search'
      | 'setPaginationOptions'
      | 'setPaginationAction'
      | 'clearPaginationOptions'
      | 'onDuplicate'
      | 'fetchSpinner'
      | 'fetchSpinnerOverride'
      | 'displayActionsLabel'
    > &
      WithTranslationProps
  >,
  Pick<
    ClassAttributes<CustomTable<T>> & IProps<T>,
    | 'pageable'
    | 'ref'
    | 'key'
    | 'headers'
    | 'onEdit'
    | 'paginationAction'
    | 'search'
    | 'onDuplicate'
    | 'fetchSpinnerOverride'
    | 'displayActionsLabel'
  >
> {
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(CustomTable as new (props: IProps<T> & WithTranslation) => CustomTable<T>));
}
