import { Box, OutlinedTextFieldProps, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { InputDiv, StyledTextField } from './CustomInput.styled';

interface IProps extends FieldRenderProps<string> {
  label: string;
  disableAutocomplete?: boolean;
  textFieldProps?: Omit<OutlinedTextFieldProps, 'variant'>;
  column?: boolean;
  width?: string;
  disabled?: boolean;
  tooltip?: string;
}

type PropType = IProps;

const CustomField = ({
  input,
  textFieldProps,
  disableAutocomplete,
  meta: { error, touched },
  width,
  label,
  column,
  disabled,
  tooltip,
}: PropType): JSX.Element => {
  const shouldDisplayError = (typeof error === 'boolean' || (error && touched)) as boolean;
  const { t } = useTranslation();

  const renderTextFieldComponent = (): JSX.Element => {
    const textComponent = (
      <StyledTextField
        {...textFieldProps}
        error={shouldDisplayError}
        variant="outlined"
        inputProps={{ ...input }}
        autoComplete={disableAutocomplete ? 'new-password' : textFieldProps?.type}
        margin="dense"
        width={width}
        disabled={disabled}
      />
    );

    if (tooltip) {
      return <Tooltip title={tooltip}>{textComponent}</Tooltip>;
    }

    return textComponent;
  };

  return (
    <InputDiv column={column}>
      <Typography component="div">
        <Box fontWeight={500} width="276px" fontSize="14px" color="#333">
          {t(label)}
        </Box>
      </Typography>
      {column ? (
        renderTextFieldComponent()
      ) : (
        <Box display="flex" flexDirection="column" width="400px">
          {renderTextFieldComponent()}
          {shouldDisplayError && typeof error !== 'boolean' && (
            <Typography variant="caption" color="error">
              {t(error as string)}
            </Typography>
          )}
        </Box>
      )}
      {column && shouldDisplayError && typeof error !== 'boolean' && (
        <Typography variant="caption" color="error">
          {t(error as string)}
        </Typography>
      )}
    </InputDiv>
  );
};

export default CustomField;
