import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { XTMConnectionParametersDTO } from 'utils/restApplicationClientTypeOverrides';
import { XTMLanguageDTO } from '../../../../xtm-kontent-app/src/utils/restApplicationClient';
import { IUserState } from './user.interface';

const authSelector = (state: AppState): boolean => state.user.auth;

const verifyFinishSelector = (state: AppState): boolean => state.user.verifyFinished;

const userDataSelector = (state: AppState): IUserState['data'] => {
  return state.user.data;
};

const userErrorsSelector = (state: AppState): IUserState['errors'] => state.user.errors;

const userSelector = (state: AppState): IUserState['user'] => state.user.user;

const xtmAuthorizationIdSelector = ({ user: { xtmAuthorizationId } }: AppState): IUserState['xtmAuthorizationId'] =>
  xtmAuthorizationId;

const xtmCustomersSelector = ({ user: { customers } }: AppState): IUserState['customers'] => customers;

const xtmTemplatesSelector = ({ user: { templates } }: AppState): IUserState['templates'] => templates;

const xtmConnectDataSelector = ({ user: { connectData } }: AppState): XTMConnectionParametersDTO | undefined =>
  connectData;

const sessionExpiredSelector = ({ user: { sessionExpired } }: AppState): boolean => sessionExpired;

export const getAuthSelector = createSelector(authSelector, (auth) => auth);

export const getVerifyFinishSelector = createSelector(verifyFinishSelector, (finished) => finished);

export const getUserDataSelector = createSelector(userDataSelector, (roles) => roles);

export const getUserErrorsSelector = createSelector(userErrorsSelector, (errors) => errors);

export const getUserSelector = createSelector(userSelector, (user) => user);

export const getXtmAuthorizationIdSelector = createSelector(
  xtmAuthorizationIdSelector,
  (authorizationId) => authorizationId,
);

export const getXtmCustomersSelector = createSelector(xtmCustomersSelector, (customers) => customers);

export const getXtmTemplatesSelector = createSelector(xtmTemplatesSelector, (templates) =>
  Array.isArray(templates) && templates.length === 0 ? undefined : templates,
);

export const getXtmConnectDataSelector = createSelector(xtmConnectDataSelector, (connectData) => connectData);

export const allDefaultUserLanguages = (state: AppState): XTMLanguageDTO[] | undefined =>
  state.user.defaultUserLanguages;
export const getDefaultUserLanguagesSelector = createSelector(allDefaultUserLanguages, (languages) => languages);

export const getSessionExpiredSeletor = createSelector(sessionExpiredSelector, (sessionExpired) => sessionExpired);
