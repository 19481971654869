/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, SelectProps, Typography } from '@material-ui/core';
import { ChangeEvent, Component } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputDiv, StyledSelect } from './CustomInput.styled';

interface IProps {
  label: string;
  meta: { error?: string; touched?: boolean };
  inputProps: FieldInputProps<unknown, HTMLElement>;
  rows: Array<{ value: string; name: string }>;
  width?: string;
  selectProps?: SelectProps;
  onChange?: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
}

export class CustomSelect extends Component<IProps & WithTranslation> {
  render(): JSX.Element {
    const {
      t,
      meta: { error, touched },
      inputProps,
      rows,
      width,
      onChange,
      label,
    } = this.props;

    return (
      <InputDiv>
        <Typography component="div">
          <Box fontWeight={500} width="276px" fontSize="14px" color="#333">
            {t(label)}
          </Box>
        </Typography>
        <Box display="flex" flexDirection="column" width="400px">
          <StyledSelect
            {...this.props.selectProps}
            error={Boolean(error) && touched}
            variant="outlined"
            margin="dense"
            onChange={onChange}
            native={this.props.selectProps?.native ? this.props.selectProps?.native : true}
            inputProps={{
              ...inputProps,
              placeholder: t('common.selectPlaceholder'),
            }}
            width={width}
          >
            {!this.props.inputProps.value && <option value={undefined}></option>}
            {rows.map((row, index) => {
              return (
                <option value={row.value} key={index}>
                  {row.name}
                </option>
              );
            })}
          </StyledSelect>
          {error && touched && (
            <Typography variant="caption" color="error" component="div">
              <Box marginTop="4px">{t(error)}</Box>
            </Typography>
          )}
        </Box>
      </InputDiv>
    );
  }
}

export default withTranslation()(CustomSelect);
