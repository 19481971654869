/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { iconButtonLabelStyle } from './IconButtonLabel.style';

interface IProps {
  buttonLabelKey: string;
  onAdd: () => void;
}

const Text = styled.span`
  letter-spacing: 0.075em;
  margin: 8px;
  font-size: 16px;
`;

class IconButtonLabel extends Component<IProps & WithTranslation> {
  render(): JSX.Element {
    const { t, buttonLabelKey, onAdd } = this.props;
    return (
      <div>
        <Button css={iconButtonLabelStyle.button} onClick={onAdd}>
          <AddCircleIcon fontSize="default" />
          <Text>{t(buttonLabelKey)}</Text>
        </Button>
      </div>
    );
  }
}

export default withTranslation()(IconButtonLabel);
