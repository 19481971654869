import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { ISpinnerState } from './spinner.interface';

const fetchSpinnerState = ({ spinner }: AppState): ISpinnerState => spinner;

export const getFetchSpinnerSelector = createSelector(fetchSpinnerState, ({ fetchSpinner }) => fetchSpinner);

export const getTemplateSpinnerSelector = createSelector(fetchSpinnerState, ({ templateSpinner }) => templateSpinner);

export const getCustomerSpinnerSelector = createSelector(fetchSpinnerState, ({ customerSpinner }) => customerSpinner);
export const getconnectDataSpinnerSelector = createSelector(
  fetchSpinnerState,
  ({ connectDataSpinner }) => connectDataSpinner,
);

export const getConnectToXtmSpinnerSelector = createSelector(
  fetchSpinnerState,
  ({ connectToXtmSpinner }) => connectToXtmSpinner,
);

export const getConnectToCMSSpinnerSelector = createSelector(
  fetchSpinnerState,
  ({ connectToCMSSpinner }) => connectToCMSSpinner,
);

export const getDefaultLanguagesSpinnerSelector = createSelector(
  fetchSpinnerState,
  ({ defaultUserLanguagesSpinner }) => defaultUserLanguagesSpinner,
);

export const getProjectSpinnerSelector = createSelector(fetchSpinnerState, ({ projectSpinner }) => projectSpinner);

export const getKenticoDataSpinnerSelector = createSelector(
  fetchSpinnerState,
  ({ kenticoDataSpinner }) => kenticoDataSpinner,
);

export const getKenticoConnectSpinnerSelector = createSelector(
  fetchSpinnerState,
  ({ kenticoConnectSpinner }) => kenticoConnectSpinner,
);
