import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { combineEpics, Epic } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  createAdministratorError,
  editAdministratorError,
  getAdministratorByIdError,
  getAdministratorsPageableError,
} from 'store/administrator/administrator.actions';
import {
  createClientError,
  editClientError,
  getAllClientsError,
  getClientByIdError,
  getClientsPageableError,
} from 'store/client/client.actions';
import { showError } from 'store/notify/notify.actions';
import {
  getProjectsPageableError,
  getContentTypesError,
  getContentElementsError,
  getWorkflowStepsError,
  getWebhooksError,
  getApiKeyError,
  addProjectError,
  editProjectError,
  getProjectByIdError,
  getKenticoLanguagesError,
  getXtmLanguagesError,
  GetProjectsByClientIdError,
} from 'store/project/project.actions';
import {
  changePasswordError,
  changeUserPasswordError,
  createUserError,
  editUserError,
  getAllUsersError,
  getDefaultUserLanguagesError,
  getUserByIdError,
  getUsersByClientIdError,
  logoutUserError,
  resetPasswordError,
  resetUserPasswordError,
  sessionExpired,
  usersPageableError,
  verifyPassTokenError,
  verifyTokenError,
  xtmGetConnectDataError,
  xtmGetCustomersByIdError,
  xtmGetCustomersError,
  xtmGetTemplatesError,
} from 'store/user/user.actions';
import { checkProjectValidation } from 'utils/checkProjectValidation';
import i18n from 'utils/initTranslation';

export enum ActionSourceType {
  ADMINISTRATOR = 'administrator',
  AUTH = 'auth',
  CLIENT = 'client',
  COMMON = 'common',
  PROJECTS = 'projects',
  TABLE = 'table',
  USERS = 'users',
  VALIDATOR = 'validator',
}

export interface ErrorData {
  args: string[];
  errorMessage: string;
  errorCode: string;
}

export interface ErrorPayload {
  error: AxiosResponse<ErrorData | ErrorData[]>;
  type?: ActionSourceType;
  skipMessage?: boolean;
}

export const AvailableErrorActionTypes = [
  createAdministratorError.type,
  getAdministratorsPageableError.type,
  getAdministratorByIdError.type,
  editAdministratorError.type,
  getAllClientsError.type,
  createClientError.type,
  getClientsPageableError.type,
  editClientError.type,
  getClientByIdError.type,
  getProjectsPageableError.type,
  getContentTypesError.type,
  getContentElementsError.type,
  getWorkflowStepsError.type,
  getWebhooksError.type,
  getApiKeyError.type,
  addProjectError.type,
  editProjectError.type,
  getProjectByIdError.type,
  getKenticoLanguagesError.type,
  getXtmLanguagesError.type,
  GetProjectsByClientIdError.type,
  verifyPassTokenError.type,
  changePasswordError.type,
  changeUserPasswordError.type,
  logoutUserError.type,
  getUsersByClientIdError.type,
  resetPasswordError.type,
  resetUserPasswordError.type,
  verifyTokenError.type,
  getAllUsersError.type,
  createUserError.type,
  usersPageableError.type,
  editUserError.type,
  getUserByIdError.type,
  xtmGetCustomersError.type,
  xtmGetTemplatesError.type,
  xtmGetCustomersByIdError.type,
  xtmGetConnectDataError.type,
  getDefaultUserLanguagesError.type,
];

/**
 * Epic for handling error actions.
 *
 * Messages are shown based on passed type. Default type is COMMON from ActionSourceType enum
 *
 * Only actions in AvailableErrorActionTypes table will be handled.
 *
 * You can pass these params to the error action.
 * - @param error: AxiosResponse<ErrorData | ErrorData[]>;
 * - @param type?: ActionSourceType;
 * - @param skipMessage?: boolean;
 */
export const handleErrorEpic: Epic = (action$) =>
  action$.pipe(
    filter(
      ({ type }: PayloadAction<ErrorPayload, string>) =>
        !!AvailableErrorActionTypes.find((actionType) => actionType === type),
    ),
    switchMap(({ payload: { error, type = ActionSourceType.COMMON, skipMessage } }) => {
      if (!error) {
        return of(showError({ message: 'common.errormessage.internalservererror' }));
      }

      const { data, status } = error;
      const errorData: ErrorData = Array.isArray(data) ? data[0] : data;
      const { errorCode } = errorData;

      if (status === 403) {
        return of(sessionExpired(true));
      }

      if (String(errorCode)?.includes('projectvalidationerror')) {
        checkProjectValidation(errorData?.args[0]);
      }

      const isTranslationAvailbable = i18n.t(`${type}.${errorCode}`) !== `${type}.${errorCode}`;
      const message = isTranslationAvailbable ? `${type}.${errorCode}` : `${type}.errormessage.internalservererror`;

      return skipMessage ? EMPTY : of(showError({ message }));
    }),
  );

export const errorEpics = combineEpics(handleErrorEpic);
