/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { Fragment, PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IProps {
  text: string;
}

class FormTitle extends PureComponent<IProps & WithTranslation> {
  StyledTypography = styled(Typography)`
    display: block;
    width: 100%;
    font-size: 17px !important;
    margin: 24px 0 !important;
    color: #00a4e4 !important;
  `;

  render(): JSX.Element {
    const { t, text } = this.props;
    const { StyledTypography } = this;
    return (
      <Fragment>
        <StyledTypography variant="h4" color="secondary" align="left">
          {t(text)}
        </StyledTypography>
      </Fragment>
    );
  }
}

export default withTranslation()(FormTitle);
