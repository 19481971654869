import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of, timer } from 'rxjs';
import { catchError, delayWhen, pluck, switchMap, tap } from 'rxjs/operators';
import store from 'store';
import { showSuccessful } from 'store/notify/notify.actions';
import { api, clearToken } from 'utils/axiosInstance';
import { AuthenticationResult } from 'utils/restApplicationClient';
import i18n from '../../utils/initTranslation';
import { setPaginationSearchSpinner } from '../table/table.actions';
import {
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  changeUserPassword,
  createUser,
  createUserError,
  createUserSuccess,
  editUser,
  editUserSuccess,
  getDefaultUserLanguages,
  getDefaultUserLanguagesError,
  getDefaultUserLanguagesSuccess,
  getUserById,
  getUserByIdError,
  getUserByIdSuccess,
  getUsersByClientId,
  getUsersByClientIdError,
  getUsersByClientIdSuccess,
  loginUser,
  loginUserError,
  loginUserSuccess,
  logoutUser,
  logoutUserSuccess,
  refreshTokenSuccess,
  resetPassword,
  resetPasswordError,
  resetPasswordSuccess,
  resetUserPassword,
  resetUserPasswordError,
  resetUserPasswordSuccess,
  sessionExpired,
  showSessionExpired,
  usersPageable,
  usersPageableError,
  usersPageableSuccess,
  verifyPassToken,
  verifyPassTokenError,
  verifyPassTokenSuccess,
  verifyToken,
  verifyTokenError,
  xtmConnect,
  xtmConnectError,
  xtmConnectSuccess,
  xtmConnectUser,
  xtmConnectUserError,
  xtmConnectUserSuccess,
  xtmGetConnectData,
  xtmGetConnectDataError,
  xtmGetConnectDataSuccess,
  xtmGetCustomers,
  xtmGetCustomersById,
  xtmGetCustomersByIdError,
  xtmGetCustomersByIdSuccess,
  xtmGetCustomersError,
  xtmGetCustomersSuccess,
  xtmGetTemplates,
  xtmGetTemplatesError,
  xtmGetTemplatesSuccess,
} from './user.actions';

export const loginEpic: Epic = (action$) =>
  action$.pipe(
    ofType(loginUser.type),
    switchMap(({ payload }) =>
      from(api.authenticateAdmin(payload)).pipe(
        switchMap(({ data }) => of(loginUserSuccess(data))),
        catchError(({ response }) => {
          return of(loginUserError({ error: response }));
        }),
      ),
    ),
  );

export const logoutEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(logoutUser.type, verifyTokenError.type),
    tap(() => clearToken()),
    switchMap(() => {
      if (
        browserHistory.location.pathname !== '/changePassword' &&
        !/\/reset-password\/.*/.test(browserHistory.location.pathname)
      ) {
        browserHistory.push('/login');
      }
      return of(logoutUserSuccess());
    }),
  );

export const refreshTokenEpic: Epic = (action$) =>
  action$.pipe(
    ofType(loginUserSuccess.type, refreshTokenSuccess.type),
    pluck('payload'),
    delayWhen(({ ttl = 890 }: AuthenticationResult) => timer((ttl - 10) * 1000)),
    switchMap(() =>
      from(api.refreshToken()).pipe(
        switchMap(({ data }) => {
          return of(refreshTokenSuccess(data));
        }),
        catchError(() => {
          return of(logoutUser());
        }),
      ),
    ),
  );

export const resetPasswordEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(resetPassword.type),
    switchMap(({ payload: { email } }) =>
      from(api.sendResetPasswordEmailForAdmin({ email, language: i18n.language })).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'auth.success.resetPasswordSent' }));
          return of(resetPasswordSuccess());
        }),
        catchError(({ response }) => {
          return of(resetPasswordError({ error: response }));
        }),
      ),
    ),
  );

export const resetUserPasswordEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(resetUserPassword.type),
    switchMap(({ payload: { email } }) =>
      from(api.sendResetPasswordEmail({ email, language: i18n.language })).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'auth.success.resetPasswordSent' }));
          return of(resetUserPasswordSuccess());
        }),
        catchError(({ response }) => {
          return of(resetUserPasswordError({ error: response }));
        }),
      ),
    ),
  );

export const verifyTokenEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(verifyToken.type),
    switchMap(() =>
      from(api.refreshToken()).pipe(
        switchMap(({ data }) => {
          return of(refreshTokenSuccess(data));
        }),
        catchError(() => {
          return of(logoutUser());
        }),
      ),
    ),
  );

export const changePassEpic: Epic = (action$, state$, { browserHistory }) =>
  action$.pipe(
    ofType(changePassword.type),
    switchMap(({ payload }) =>
      from(api.changePassword(payload)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'auth.success.changePassword' }));
          browserHistory.push('/login');
          return of(changePasswordSuccess());
        }),
        catchError(({ response }) => of(changePasswordError({ error: response }))),
      ),
    ),
  );

export const changeUserPassEpic: Epic = (action$) =>
  action$.pipe(
    ofType(changeUserPassword.type),
    switchMap(({ payload }) =>
      from(api.changePassword(payload)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'auth.success.changePassword' }));
          window.location.href = 'https://app.contentful.com/';
          return of(changePasswordSuccess());
        }),
        catchError(({ response }) => of(changePasswordError({ error: response }))),
      ),
    ),
  );

export const verifyResetPasswordTokenEpic: Epic = (actions$, state$, { browserHistory }) =>
  actions$.pipe(
    ofType(verifyPassToken.type),
    switchMap(({ payload: token }) =>
      from(api.verifyResetPasswordToken({ token })).pipe(
        switchMap(() => of(verifyPassTokenSuccess())),
        catchError(({ response }) => {
          browserHistory.push('/login');
          return of(verifyPassTokenError({ error: response }));
        }),
      ),
    ),
  );

export const addUserEpic: Epic = (action$, state$, { browserHistory }) =>
  action$.pipe(
    ofType(createUser.type),
    switchMap(({ payload }) =>
      from(api.createUser(payload)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: i18n.t('users.success.create') }));
          browserHistory.push('/users');
          return of(createUserSuccess());
        }),
        catchError(({ response }) => of(createUserError({ error: response }))),
      ),
    ),
  );

export const getUsersPageableEpic: Epic = (action$) =>
  action$.pipe(
    ofType(usersPageable.type),
    switchMap(({ payload }) =>
      from(api.getAllUsers(payload)).pipe(
        switchMap(({ data }) => {
          store.dispatch(setPaginationSearchSpinner(false));
          return of(usersPageableSuccess(data));
        }),
        catchError(({ response }) => of(usersPageableError({ error: response }), setPaginationSearchSpinner(false))),
      ),
    ),
  );

export const editUserEpic: Epic = (actions$, state$, { browserHistory }) =>
  actions$.pipe(
    ofType(editUser.type),
    switchMap((res) => {
      return from(api.editUser(res.payload.userId, res.payload.updateUser)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'users.success.edit' }));
          browserHistory.push('/users');
          return of(editUserSuccess());
        }),
        catchError(({ response }) => of(createUserError({ error: response }))),
      );
    }),
  );

export const getUserEpic: Epic = (actions$, state$, { browserHistory }) =>
  actions$.pipe(
    ofType(getUserById.type),
    switchMap(({ payload }) => from(api.getUser(payload)).pipe(switchMap(({ data }) => of(getUserByIdSuccess(data))))),
    catchError(({ response }) => {
      browserHistory.push('/users');
      return of(getUserByIdError({ error: response }));
    }),
  );

export const connectXtmEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmConnect.type),
    switchMap(({ payload }) =>
      from(api.connectToXtm(payload)).pipe(
        switchMap(({ data }) => of(xtmConnectSuccess(data))),
        catchError(({ response }) => of(xtmConnectError({ error: response }))),
      ),
    ),
  );

export const connectXtmUserEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmConnectUser.type),
    switchMap(({ payload }) =>
      from(api.reconnectToXtm(payload.userId, payload.xtmParameters)).pipe(
        switchMap(({ data }) => {
          return of(xtmConnectUserSuccess(data));
        }),
        catchError(({ response }) => of(xtmConnectUserError({ error: response }))),
      ),
    ),
  );

export const getCustomersEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetCustomers.type, xtmConnectSuccess.type, xtmConnectUserSuccess.type),
    switchMap(({ payload }) =>
      from(
        api.getXTMCustomers$GET$api_xtm_customers({
          xtmAuthId: payload.xtmAuthorizationId,
        }),
      ).pipe(
        switchMap(({ data }) => of(xtmGetCustomersSuccess(data))),
        catchError(({ response }) => of(xtmGetCustomersError({ error: response }))),
      ),
    ),
  );

export const getTemplatesEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetTemplates.type),
    switchMap(({ payload }) =>
      from(api.getXTMTemplates(payload)).pipe(
        switchMap(({ data }) => of(xtmGetTemplatesSuccess(data))),
        catchError(({ response }) => of(xtmGetTemplatesError({ error: response }))),
      ),
    ),
  );

export const getCustomersByIdEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetCustomersById.type),
    switchMap(({ payload }) =>
      from(api.getXTMCustomersForExistingUser(payload)).pipe(
        switchMap(({ data }) => of(xtmGetCustomersByIdSuccess(data))),
        catchError(({ response }) => of(xtmGetCustomersByIdError({ error: response }))),
      ),
    ),
  );

export const getConnectDataEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetConnectData.type),
    switchMap(({ payload }) =>
      from(api.getXTMConnectionParameters(payload)).pipe(
        switchMap(({ data }) => of(xtmGetConnectDataSuccess(data))),
        catchError(({ response }) => of(xtmGetConnectDataError({ error: response }))),
      ),
    ),
  );

export const showSessionExpiredEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(sessionExpired.type),
    switchMap(() => of(showSessionExpired())),
  );

export const getUsersByClientIdEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(getUsersByClientId.type),
    switchMap(({ payload }) =>
      from(api.getAllUsersList({ clientUUID: payload })).pipe(
        switchMap(({ data }) => of(getUsersByClientIdSuccess(data))),
        catchError(({ response }) => of(getUsersByClientIdError({ error: response }))),
      ),
    ),
  );

const getDefaultUserLanguagesList: Epic = (actions$) =>
  actions$.pipe(
    ofType(getDefaultUserLanguages.type),
    switchMap(() =>
      from(api.getLanguages()).pipe(
        switchMap(({ data }) => of(getDefaultUserLanguagesSuccess(data))),
        catchError(({ response }) => {
          return of(getDefaultUserLanguagesError({ error: response }));
        }),
      ),
    ),
  );

export const userEpics = combineEpics(
  loginEpic,
  logoutEpic,
  resetPasswordEpic,
  refreshTokenEpic,
  verifyTokenEpic,
  verifyResetPasswordTokenEpic,
  changePassEpic,
  getUsersPageableEpic,
  addUserEpic,
  getUserEpic,
  editUserEpic,
  connectXtmEpic,
  getCustomersEpic,
  getTemplatesEpic,
  getCustomersByIdEpic,
  getConnectDataEpic,
  showSessionExpiredEpic,
  connectXtmUserEpic,
  resetUserPasswordEpic,
  changeUserPassEpic,
  getUsersByClientIdEpic,
  getDefaultUserLanguagesList,
);
