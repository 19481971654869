/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

interface IProps {
  text: string;
  wide?: boolean;
  linkProps: React.PropsWithoutRef<LinkProps> & React.RefAttributes<HTMLAnchorElement>;
}

class CustomLink extends PureComponent<WithTranslation & IProps> {
  StyledDiv = styled.div`
    font-weight: bold;
    text-align: start;
    ${this.props.wide ? 'width: 100%;' : ''}

    & > a {
      transition: opacity 0.2s;
    }

    & > a:hover {
      opacity: 0.8;
    }
  `;

  render(): JSX.Element {
    const { t, text, linkProps } = this.props;
    return (
      <this.StyledDiv>
        <Link {...linkProps}>{t(text)}</Link>
      </this.StyledDiv>
    );
  }
}

export default withTranslation()(CustomLink);
