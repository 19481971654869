/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FormApi } from 'final-form';
import { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IProjectFormData } from 'store/project/project.interface';
import { IAutocompleteField } from 'types/shared';
import CustomAutocomplete from '../Input/CustomAutocomplete';
import DraggableSelect from '../Input/DraggableSelect/DraggableSelect';
import {
  CustomLanguageMappingContainer,
  CustomLanguageMappingLegend,
} from './LanguageMapping/LanguageMappingContainer.styled';

interface IProps {
  users: IAutocompleteField[];
  workflowSteps?: IAutocompleteField[];
  webhooks?: IAutocompleteField[];
  contentTypes?: IAutocompleteField[];
  contentElements?: IAutocompleteField[];
  kenticoSpinner?: boolean;
  fetchSpinner?: boolean;
  form: FormApi<IProjectFormData, Partial<IProjectFormData>>;
}

type PropType = IProps & WithTranslation;

export class ConnectedProjectFields extends Component<PropType> {
  usersForWebhooksRef?: HTMLInputElement;

  render(): JSX.Element {
    const {
      users,
      webhooks,
      workflowSteps,
      contentTypes,
      contentElements,
      kenticoSpinner,
      fetchSpinner,
      t,
    } = this.props;

    return (
      <Fragment>
        <Field
          name="contentTypes"
          component={DraggableSelect}
          spinner={kenticoSpinner}
          label="projects.contentTypes"
          options={contentTypes}
        />
        <Field
          name="contentElements"
          component={DraggableSelect}
          spinner={kenticoSpinner}
          label="projects.contentElements"
          options={contentElements}
        />
        <Field
          name="workflowSteps"
          component={DraggableSelect}
          spinner={kenticoSpinner}
          label="projects.workflowSteps"
          options={workflowSteps}
        />
        <Field
          name="workflowStep"
          component={CustomAutocomplete}
          spinner={kenticoSpinner}
          label="projects.workflowStep"
          options={workflowSteps}
        />
        <CustomLanguageMappingContainer>
          <CustomLanguageMappingLegend>{t('projects.webhooks')}</CustomLanguageMappingLegend>
          <Field
            name="webhooks"
            component={DraggableSelect}
            spinner={kenticoSpinner}
            label="projects.webhooks"
            options={webhooks}
          />
          <Field
            name="usersForWebhooks"
            component={DraggableSelect}
            label="projects.usersForWebhooks"
            spinner={fetchSpinner}
            options={users}
            allowDragAndDrop
          />
        </CustomLanguageMappingContainer>
        <Field
          name="usersAssigned"
          component={DraggableSelect}
          label="projects.usersAssigned"
          spinner={fetchSpinner}
          options={users}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(ConnectedProjectFields);
