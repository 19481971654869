import CustomTableContainer from 'components/Table/CustomTable';
import TableToolbar from 'components/TableToolbar/TableToolbar';
import { OrderDirection } from 'enums/filter';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { getProjectsPageable } from 'store/project/project.actions';
import { getProjectsPageableSelector } from 'store/project/project.selectors';
import { setPaginationOptions } from 'store/table/table.actions';
import { ITablePaginationOptions } from 'store/table/table.interface';
import { KontentProjectOverviewDTO, Page } from 'utils/restApplicationClient';

interface IStateProps {
  projects?: Page<KontentProjectOverviewDTO>;
}

interface IDispatchProps {
  setPaginationOptions: (payload: ITablePaginationOptions) => AppDispatch;
}

const ProjectsTable = CustomTableContainer<KontentProjectOverviewDTO>();

type IProps = IStateProps & RouteComponentProps & IDispatchProps;

class ProjectsContainer extends Component<IProps> {
  headers = [
    { label: 'common.id', id: 'id' },
    { label: 'common.kenticoProjectName', id: 'name' },
    { label: 'common.clientName', id: 'client.clientName' },
    { label: 'common.status', id: 'status' },
    { label: 'common.creationDate', id: 'createdAt' },
    { label: 'common.modificationDate', id: 'modifiedAt' },
  ];

  onEdit = (row: KontentProjectOverviewDTO): void => {
    this.props.history.push(`project/edit/${row.id}`);
  };

  onAdd = (): void => {
    this.props.history.push('project/add');
  };

  componentDidMount(): void {
    this.props.setPaginationOptions({
      order: OrderDirection.ASC,
      orderBy: 'client.clientName',
    });
  }

  render(): JSX.Element {
    const { projects } = this.props;
    return (
      <div>
        <TableToolbar buttonLabelKey="projects.add" onAdd={this.onAdd} />
        <ProjectsTable
          headers={this.headers}
          onEdit={this.onEdit}
          paginationAction={getProjectsPageable}
          pageable={projects}
          search={''}
          displayActionsLabel
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  projects: getProjectsPageableSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setPaginationOptions: (payload: ITablePaginationOptions): AppDispatch =>
    dispatch(setPaginationOptions(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectsContainer));
