export const ProjectActionTypes = {
  GetProjectsPageable: 'PROJECTS_GET_PROJECTS_PAGEABLE',
  GetProjectsPageableSuccess: 'PROJECTS_GET_PROJECTS_PAGEABLE_SUCCESS',
  GetProjectsPageableError: 'PROJECTS_GET_PROJECTS_PAGEABLE_ERROR',
  GetWorkflowSteps: 'GET_WORKFLOW_STEPS',
  GetWorkflowStepsSuccess: 'PGET_WORKFLOW_STEPS_SUCCESS',
  GetWorkflowStepsError: 'GET_WORKFLOW_STEPS_ERROR',
  GetWebhooks: 'GET_WEBHOOKS',
  GetWebhooksSuccess: 'GET_WEBHOOKS_SUCCESS',
  GetWebhooksError: 'GET_WEBHOOKS_ERROR',
  GetContentTypes: 'GET_CONTENT_TYPES',
  GetContentTypesError: 'GET_CONTENT_TYPES_ERROR',
  GetContentTypesSuccess: 'GET_CONTENT_TYPES_SUCCESS',
  GetContentElements: 'GET_CONTENT_ELEMENTS',
  GetContentElementsError: 'GET_CONTENT_ELEMENTS_ERROR',
  GetContentElementsSuccess: 'GET_CONTENT_ELEMENTS_SUCCESS',
  ConnectToKentico: 'CONNECT_TO_KENTICO',
  ConnectToKenticoSuccess: 'CONNECT_TO_KENTICO_SUCCESS',
  ConnectToKenticoError: 'CONNECT_TO_KENTICO_ERROR',
  GetApiKey: 'GET_API_KEY',
  GetApiKeySuccess: 'GET_API_KEY_SUCCESS',
  GetApiKeyError: 'GET_API_KEY_ERROR',
  AddProject: 'ADD_PROJECT',
  AddProjectSuccess: 'ADD_PROJECT_SUCCESS',
  AddProjectError: 'ADD_PROJECT_ERROR',
  EditProject: 'EDIT_PROJECT',
  EditProjectSuccess: 'EDIT_PROJECT_SUCCESS',
  EditProjectError: 'EDIT_PROJECT_ERROR',
  GetProjectById: 'GET_PROJECT_BY_ID',
  GetProjectByIdSuccess: 'GET_PROJECT_BY_ID_SUCCESS',
  GetProjectByIdError: 'GET_PROJECT_BY_ID_ERROR',
  ClearProjectErrors: 'CLEAR_PROJECT_ERROR',
  GetXTMLanguages: 'GET_XTM_LANGUAGES',
  GetXTMLanguagesSuccess: 'GET_XTM_LANGUAGES_SUCCESS',
  GetXTMLanguagesError: 'GET_XTM_LANGUAGES_ERROR',
  GetKenticoLanguages: 'GET_KENTICO_LANGUAGES',
  GetKenticoLanguagesSuccess: 'GET_KENTICO_LANGUAGES_SUCCESS',
  GetKenticoLanguagesError: 'GET_KENTICO_LANGUAGES_ERROR',
  GetProjectsByClientId: 'PROJECT_GET_PROJECTS_BY_CLIENT_ID',
  GetProjectsByClientIdSuccess: 'PROJECT_GET_PROJECTS_BY_CLIENT_ID_SUCCESS',
  GetProjectsByClientIdError: 'PROJECT_GET_PROJECTS_BY_CLIENT_ID_ERROR',
  updateFormProject: 'UPDATE_FORM_PROJECT',
  clearProjectFormAll: 'CLEAR_PROJECT_FORM_ALL',
  allFormObservers: 'ALL_FORM_OBSERVER',
};
