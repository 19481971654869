/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, CircularProgress } from '@material-ui/core';
import CustomLink from 'components/CustomLink/CustomLink';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomField from 'components/Form/Input/CustomField';
import FormTitle from 'components/Form/Title/FormTitle';
import { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import { resetPassword } from 'store/user/user.actions';
import { IRemindPasswordPayload } from 'store/user/user.actions.types';
import { composeValidators, email, required } from 'utils/customValidators';
import { resetPasswordContainerStyles } from './ResetPasswordContainer.style';

interface IFormValues {
  email: string;
}

interface IDispatchProps {
  resetPassword: (payload: IRemindPasswordPayload) => void;
}

interface IStateProps {
  fetchSpinner: boolean;
}
export class ResetPasswordContainer extends Component<WithTranslation & IDispatchProps & IStateProps> {
  onSubmit = (values: IFormValues): void => {
    this.props.resetPassword(values);
  };

  render(): JSX.Element {
    const { t, fetchSpinner } = this.props;
    return (
      <FormDiv width={500}>
        <FormTitle text="auth.password.reset" />
        <span css={resetPasswordContainerStyles.text}>{t('auth.password.resetInfo')}</span>
        <Form
          onSubmit={this.onSubmit}
          render={({ handleSubmit, submitting }): JSX.Element => (
            <form onSubmit={handleSubmit} css={resetPasswordContainerStyles.form}>
              <Field
                name="email"
                component={CustomField}
                validate={composeValidators([required, email])}
                label="common.email"
                column
              />
              <Button
                variant="contained"
                color="secondary"
                disabled={submitting || fetchSpinner}
                type="submit"
                css={resetPasswordContainerStyles.button}
              >
                {fetchSpinner ? <CircularProgress size={24} color="secondary" /> : t('common.send')}
              </Button>
            </form>
          )}
        />
        <CustomLink wide text="common.cancel" linkProps={{ to: '/login' }} />
      </FormDiv>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  fetchSpinner: getFetchSpinnerSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  resetPassword: (payload: IRemindPasswordPayload): AppDispatch => dispatch(resetPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResetPasswordContainer));
