import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { IProjectState } from './project.interface';

export const projectStateSelector = (state: AppState): IProjectState => state.project;

export const getProjectsPageableSelector = createSelector(
  projectStateSelector,
  ({ projectsPageable }) => projectsPageable,
);

export const getProjectErrorsSelector = createSelector(projectStateSelector, ({ errors }) => errors);

export const getProjectFormSelector = createSelector(projectStateSelector, ({ form }) => form);

export const getClientProjectsSelector = createSelector(projectStateSelector, ({ clientProjects }) => clientProjects);
