import { createAction } from '@reduxjs/toolkit';
import { ErrorPayload } from 'store/error/error.epic';
import { IPaginationOptions } from 'types/api';
import {
  ApiKeysDTO,
  CreateOrUpdateProjectDTO,
  KontentContentElementTypeDTO,
  KontentContentTypeDTO,
  KontentProjectDTO,
  KontentProjectOverviewDTO,
  KontentWebhookDTO,
  KontentWorkflowStepDTO,
  Page,
  XTMLanguageDTO,
} from 'utils/restApplicationClient';
import { KontentLanguageDTO } from '../../../../xtm-kontent-app/src/utils/restApplicationClient';
import { ProjectActionTypes } from './project.actions.types';
import { IConntectedProject, IProjectForm } from './project.interface';

export const getProjectsPageable = createAction<IPaginationOptions>(ProjectActionTypes.GetProjectsPageable);

export const getProjectsPageableSuccess = createAction<Page<KontentProjectOverviewDTO>>(
  ProjectActionTypes.GetProjectsPageableSuccess,
);

export const getProjectsPageableError = createAction<ErrorPayload>(ProjectActionTypes.GetProjectsPageableError);

export const getContentTypes = createAction(ProjectActionTypes.GetContentTypes);

export const getContentTypesSuccess = createAction<KontentContentTypeDTO[]>(ProjectActionTypes.GetContentTypesSuccess);

export const getContentTypesError = createAction<ErrorPayload>(ProjectActionTypes.GetContentTypesError);

export const getContentElements = createAction(ProjectActionTypes.GetContentElements);

export const getContentElementsSuccess = createAction<KontentContentElementTypeDTO[]>(
  ProjectActionTypes.GetContentElementsSuccess,
);

export const getContentElementsError = createAction<ErrorPayload>(ProjectActionTypes.GetContentElementsError);

export const getWorkflowSteps = createAction(ProjectActionTypes.GetWorkflowSteps);

export const getWorkflowStepsSuccess = createAction<KontentWorkflowStepDTO[]>(
  ProjectActionTypes.GetWorkflowStepsSuccess,
);

export const getWorkflowStepsError = createAction<ErrorPayload>(ProjectActionTypes.GetProjectsPageableError);

export const getWebhooks = createAction(ProjectActionTypes.GetWebhooks);

export const getWebhooksSuccess = createAction<KontentWebhookDTO[]>(ProjectActionTypes.GetWebhooksSuccess);

export const getWebhooksError = createAction<ErrorPayload>(ProjectActionTypes.GetWebhooksError);

export const connectToKentico = createAction<IConntectedProject>(ProjectActionTypes.ConnectToKentico);

export const connectToKenticoSuccess = createAction<IConntectedProject>(ProjectActionTypes.ConnectToKenticoSuccess);

export const connectToKenticoError = createAction<ErrorPayload>(ProjectActionTypes.ConnectToKenticoError);

export const getApiKey = createAction<string>(ProjectActionTypes.GetApiKey);

export const getApiKeySuccess = createAction<ApiKeysDTO>(ProjectActionTypes.GetApiKeySuccess);

export const getApiKeyError = createAction<ErrorPayload>(ProjectActionTypes.GetApiKeyError);

export const addProject = createAction<CreateOrUpdateProjectDTO>(ProjectActionTypes.AddProject);

export const addProjectSuccess = createAction(ProjectActionTypes.AddProjectSuccess);

export const addProjectError = createAction<ErrorPayload>(ProjectActionTypes.AddProjectError);

export const editProject = createAction<{
  projectId: string;
  projectData: CreateOrUpdateProjectDTO;
}>(ProjectActionTypes.EditProject);

export const editProjectSuccess = createAction(ProjectActionTypes.EditProjectSuccess);

export const editProjectError = createAction<ErrorPayload>(ProjectActionTypes.EditProjectError);

export const getProjectById = createAction<string>(ProjectActionTypes.GetProjectById);

export const getProjectByIdSuccess = createAction<KontentProjectDTO>(ProjectActionTypes.GetProjectByIdSuccess);

export const getProjectByIdError = createAction<ErrorPayload>(ProjectActionTypes.GetProjectByIdError);

export const clearProjectErrors = createAction(ProjectActionTypes.ClearProjectErrors);

export const getKenticoLanguages = createAction<string>(ProjectActionTypes.GetKenticoLanguages);

export const getKenticoLanguagesSuccess = createAction<KontentLanguageDTO[]>(
  ProjectActionTypes.GetKenticoLanguagesSuccess,
);

export const getKenticoLanguagesError = createAction<ErrorPayload>(ProjectActionTypes.GetKenticoLanguagesError);

export const getXtmLanguages = createAction(ProjectActionTypes.GetXTMLanguages);

export const getXtmLanguagesSuccess = createAction<XTMLanguageDTO[]>(ProjectActionTypes.GetXTMLanguagesSuccess);

export const getXtmLanguagesError = createAction<ErrorPayload>(ProjectActionTypes.GetXTMLanguagesError);

export const GetProjectsByClientId = createAction<{ clientUUID: string }>(ProjectActionTypes.GetProjectsByClientId);

export const GetProjectsByClientIdSuccess = createAction<Array<KontentProjectOverviewDTO>>(
  ProjectActionTypes.GetProjectsByClientIdSuccess,
);

export const GetProjectsByClientIdError = createAction<ErrorPayload>(ProjectActionTypes.GetProjectsByClientIdError);

export const updateFormProject = createAction<KontentProjectDTO>(ProjectActionTypes.updateFormProject);

export const clearProjectFormAll = createAction(ProjectActionTypes.clearProjectFormAll);

export const allFormObservers = createAction<IProjectForm>(ProjectActionTypes.allFormObservers);