import { css } from '@emotion/core';

export const navbarStyles = {
  root: css`
    display: flex;
    background: #fff;
    padding: 0 15px;
    border-bottom: 1px solid rgba(140, 140, 140, 0.4);
  `,
};
