/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import CustomAutocomplete from 'components/Form/Input/CustomAutocomplete';
import { FormApi } from 'final-form';
import { Component } from 'react';
import { Field } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IProjectFormData } from 'store/project/project.interface';
import { IAutocompleteField, ILanguageMappingField } from 'types/shared';
import { requiredAutocomplete } from 'utils/customValidators';
import {
  CustomLanguageMappingButton,
  CustomLanguageMappingContainer,
  CustomLanguageMappingFieldContainer,
  CustomLanguageMappingLegend,
  CustomLanguageMappingRemoveButton,
} from './LanguageMappingContainer.styled';

interface IState {
  filteredLanguages: IAutocompleteField[][];
}

interface IProps {
  form: FormApi<IProjectFormData>;
  kenticoLanguages?: IAutocompleteField[];
  xtmLanguages?: IAutocompleteField[];
}

type PropType = IProps & WithTranslation;

export class LanguageMappingContainer extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      filteredLanguages: [],
    };
  }

  componentDidUpdate(): void {
    const { form, kenticoLanguages } = this.props;
    const { filteredLanguages } = this.state;

    const languageMappingFields = form.getFieldState('languageMappingFields')?.value;

    if (languageMappingFields?.length && kenticoLanguages && !filteredLanguages?.length) {
      this.setFilteredLanguages();
    }
  }

  setFilteredLanguages(): void {
    const { form, kenticoLanguages } = this.props;

    setTimeout(() => {
      const languageMappingFields = form.getFieldState('languageMappingFields')?.value;

      let filteredLanguages: IAutocompleteField[][] = [];

      const choosedKenticoLanguages: string[] =
        languageMappingFields?.map(({ kenticoLanguage }) => kenticoLanguage?.value) || [];

      filteredLanguages =
        languageMappingFields?.map((language, index) =>
          (kenticoLanguages ? kenticoLanguages?.filter(
            ({ value }) =>
              !choosedKenticoLanguages.includes(value) || languageMappingFields[index].kenticoLanguage?.value === value,
          ) : [])
        ) || [];

      this.setState({
        filteredLanguages,
      });
    }, 1);
  }

  getKenticoLanguageValue(index: number): string | undefined {
    const { form } = this.props;
    const languageMappingField = form.getFieldState('languageMappingFields')?.value;

    return languageMappingField && languageMappingField[index].kenticoLanguage?.value;
  }

  handleAddLanguageMapping = (
    fields: FieldArrayRenderProps<ILanguageMappingField, HTMLElement>['fields'],
  ) => (): void => {
    fields.push({ xtmLanguage: { label: '', value: '' }, kenticoLanguage: { label: '', value: '' } });
    this.setFilteredLanguages();
  };

  handleRemoveLanguageMapping = (
    fields: FieldArrayRenderProps<ILanguageMappingField, HTMLElement>['fields'],
    index: number,
  ) => (): void => {
    fields.remove(index);
    this.setFilteredLanguages();
  };

  onKenticoLanguageChange = (index: number, fields: FieldArrayRenderProps<string, HTMLElement>['fields']) => (
    event: React.ChangeEvent<{}>,
    value: IAutocompleteField | Array<IAutocompleteField> | null,
  ): void => {
    if (!value) {
      fields.update(index, '');
    }

    this.setFilteredLanguages();
  };

  render(): JSX.Element {
    const { filteredLanguages } = this.state;
    const { t, kenticoLanguages, xtmLanguages } = this.props;

    return (
      <FieldArray name="languageMappingFields">
        {({ fields }): JSX.Element => (
          <CustomLanguageMappingContainer>
            <CustomLanguageMappingLegend>{t('projects.languageMapping')}</CustomLanguageMappingLegend>
            {fields.map(
              (name, index: number): JSX.Element => (
                <Box display="flex" key={name} justifyContent="space-between" alignItems="flex-start">
                  <CustomLanguageMappingFieldContainer>
                    <Field
                      name={`${name}.kenticoLanguage`}
                      validate={requiredAutocomplete}
                      label="projects.kenticoLanguage"
                      options={filteredLanguages[index] || []}
                      component={CustomAutocomplete}
                      column
                      placeholder={t('common.selectPlaceholder')}
                      onChange={this.onKenticoLanguageChange(index, fields)}
                    />
                  </CustomLanguageMappingFieldContainer>

                  <CustomLanguageMappingFieldContainer>
                    <Field
                      name={`${name}.xtmLanguage`}
                      validate={requiredAutocomplete}
                      label="projects.xtmLanguage"
                      options={xtmLanguages}
                      component={CustomAutocomplete}
                      column
                      placeholder={t('common.selectPlaceholder')}
                      disabled={!this.getKenticoLanguageValue(index)}
                    />
                  </CustomLanguageMappingFieldContainer>

                  <CustomLanguageMappingRemoveButton onClick={this.handleRemoveLanguageMapping(fields, index)}>
                    <CloseIcon />
                  </CustomLanguageMappingRemoveButton>
                </Box>
              ),
            )}
            {kenticoLanguages?.length !== fields?.length && (
              <CustomLanguageMappingButton onClick={this.handleAddLanguageMapping(fields)}>
                <AddCircleIcon fontSize="large" />
              </CustomLanguageMappingButton>
            )}
          </CustomLanguageMappingContainer>
        )}
      </FieldArray>
    );
  }
}

export default withTranslation()(LanguageMappingContainer);
