import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import {
  createAdministrator,
  createAdministratorError,
  createAdministratorSuccess,
  getAdministratorById,
  getAdministratorByIdError,
  getAdministratorByIdSuccess,
  getAdministratorsPageable,
  getAdministratorsPageableError,
  getAdministratorsPageableSuccess,
} from 'store/administrator/administrator.actions';
import {
  createClient,
  createClientError,
  createClientSuccess,
  editClient,
  editClientSuccess,
  getAllClients,
  getAllClientsError,
  getAllClientsSuccess,
  getClientById,
  getClientByIdError,
  getClientByIdSuccess,
  getClientsPageable,
  getClientsPageableError,
  getClientsPageableSuccess,
} from 'store/client/client.actions';
import {
  addProject,
  addProjectError,
  addProjectSuccess,
  connectToKentico,
  connectToKenticoError,
  connectToKenticoSuccess,
  editProject,
  editProjectError,
  editProjectSuccess,
  getApiKey,
  getApiKeyError,
  getApiKeySuccess,
  getContentElements,
  getContentElementsError,
  getContentElementsSuccess,
  getContentTypes,
  getContentTypesError,
  getContentTypesSuccess,
  getProjectById,
  getProjectByIdError,
  getProjectByIdSuccess,
  GetProjectsByClientId,
  GetProjectsByClientIdError,
  GetProjectsByClientIdSuccess,
  getProjectsPageable,
  getProjectsPageableError,
  getProjectsPageableSuccess,
  getWebhooks,
  getWebhooksError,
  getWebhooksSuccess,
  getWorkflowSteps,
  getWorkflowStepsError,
  getWorkflowStepsSuccess,
} from 'store/project/project.actions';
import {
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  changeUserPassword,
  changeUserPasswordError,
  changeUserPasswordSuccess,
  createUser,
  createUserError,
  createUserSuccess,
  editUser,
  editUserSuccess,
  getDefaultUserLanguages,
  getDefaultUserLanguagesError,
  getDefaultUserLanguagesSuccess,
  getUserById,
  getUserByIdError,
  getUserByIdSuccess,
  getUsersByClientId,
  getUsersByClientIdError,
  getUsersByClientIdSuccess,
  loginUser,
  loginUserError,
  loginUserSuccess,
  resetPassword,
  resetPasswordError,
  resetPasswordSuccess,
  resetUserPassword,
  resetUserPasswordError,
  resetUserPasswordSuccess,
  usersPageable,
  usersPageableError,
  usersPageableSuccess,
  xtmConnect,
  xtmConnectError,
  xtmConnectSuccess,
  xtmConnectUser,
  xtmConnectUserError,
  xtmConnectUserSuccess,
  xtmGetConnectData,
  xtmGetConnectDataError,
  xtmGetConnectDataSuccess,
  xtmGetCustomers,
  xtmGetCustomersById,
  xtmGetCustomersByIdError,
  xtmGetCustomersByIdSuccess,
  xtmGetCustomersError,
  xtmGetCustomersSuccess,
  xtmGetTemplates,
  xtmGetTemplatesError,
  xtmGetTemplatesSuccess,
} from 'store/user/user.actions';
import {
  setConnectDataSpinner,
  setConnectToXtmSpinner,
  setCustomerSpinner,
  setDefaultUserLanguageSpinner,
  setFetchSpinner,
  setKenticoConnectSpinner,
  setKenticoDataSpinner,
  setProjectSpinner,
  setTemplateSpinner,
} from './spinner.actions';

const fetchSpinnerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(
      createUser.type,
      createUserSuccess.type,
      createUserError.type,
      usersPageable.type,
      usersPageableSuccess.type,
      usersPageableError.type,
      editUser.type,
      editUserSuccess.type,
      getUserById.type,
      getUserByIdSuccess.type,
      getUserByIdError.type,
      createClient.type,
      createClientSuccess.type,
      createClientError.type,
      getClientsPageable.type,
      getClientsPageableError.type,
      getClientsPageableSuccess.type,
      editClient.type,
      editClientSuccess.type,
      getClientById.type,
      getClientByIdError.type,
      getClientByIdSuccess.type,
      getAllClients.type,
      getAllClientsError.type,
      getAllClientsSuccess.type,
      getUsersByClientIdError.type,
      getUsersByClientIdSuccess.type,
      getUsersByClientId.type,
      createAdministrator.type,
      createAdministratorSuccess.type,
      createAdministratorError.type,
      getAdministratorsPageable.type,
      getAdministratorsPageableSuccess.type,
      getAdministratorsPageableError.type,
      getAdministratorById.type,
      getAdministratorByIdSuccess.type,
      getAdministratorByIdError.type,
      loginUser.type,
      loginUserError.type,
      loginUserSuccess.type,
      resetPassword.type,
      resetPasswordError.type,
      resetPasswordSuccess.type,
      changePassword.type,
      changePasswordError.type,
      changePasswordSuccess.type,
      changeUserPassword.type,
      changeUserPasswordError.type,
      changeUserPasswordSuccess.type,
      resetUserPassword.type,
      resetUserPasswordError.type,
      resetUserPasswordSuccess.type,
      getProjectsPageable.type,
      getProjectsPageableSuccess.type,
      getProjectsPageableError.type,
      getContentElements.type,
      getContentElementsSuccess.type,
      getContentElementsError.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setFetchSpinner(false));
      }
      return of(setFetchSpinner(true));
    }),
  );

const templateSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetTemplates.type, xtmGetTemplatesSuccess.type, xtmGetTemplatesError.type),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setTemplateSpinner(false));
      }
      return of(setTemplateSpinner(true));
    }),
  );

const customerSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(
      xtmGetCustomers.type,
      xtmGetCustomersSuccess.type,
      xtmGetCustomersError.type,
      xtmGetCustomersById.type,
      xtmGetCustomersByIdError.type,
      xtmGetCustomersByIdSuccess.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setCustomerSpinner(false));
      }
      return of(setCustomerSpinner(true));
    }),
  );

const connectDataSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetConnectData.type, xtmGetConnectDataSuccess.type, xtmGetConnectDataError.type),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setConnectDataSpinner(false));
      }
      return of(setConnectDataSpinner(true));
    }),
  );

const connectToXtmSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(
      xtmConnect.type,
      xtmConnectError.type,
      xtmConnectSuccess.type,
      xtmConnectUser.type,
      xtmConnectUserError.type,
      xtmConnectUserSuccess.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setConnectToXtmSpinner(false));
      }
      return of(setConnectToXtmSpinner(true));
    }),
  );

const defaultUserLanguagesSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(getDefaultUserLanguages.type, getDefaultUserLanguagesSuccess.type, getDefaultUserLanguagesError.type),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setDefaultUserLanguageSpinner(false));
      }
      return of(setDefaultUserLanguageSpinner(true));
    }),
  );

const projectSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(
      addProject.type,
      addProjectSuccess.type,
      addProjectError.type,
      editProject.type,
      editProjectSuccess.type,
      editProjectError.type,
      GetProjectsByClientId.type,
      GetProjectsByClientIdSuccess.type,
      GetProjectsByClientIdError.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setProjectSpinner(false));
      }
      return of(setProjectSpinner(true));
    }),
  );

const kenticoDataSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(
      getWorkflowSteps.type,
      getWorkflowStepsError.type,
      getWorkflowStepsSuccess.type,
      getWebhooks.type,
      getWebhooksError.type,
      getWebhooksSuccess.type,
      getContentTypesSuccess.type,
      getContentTypesError.type,
      getContentTypes.type,
      getProjectById.type,
      getProjectByIdSuccess.type,
      getProjectByIdError.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setKenticoDataSpinner(false));
      }
      return of(setKenticoDataSpinner(true));
    }),
  );

const kenticoConnectSpinnerEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(
      connectToKentico.type,
      connectToKenticoError.type,
      connectToKenticoSuccess.type,
      getApiKeySuccess.type,
      getApiKeyError.type,
      getApiKey.type,
    ),
    debounceTime(500),
    switchMap(({ type }) => {
      if ((type as string).endsWith('SUCCESS') || (type as string).endsWith('ERROR')) {
        return of(setKenticoConnectSpinner(false));
      }
      return of(setKenticoConnectSpinner(true));
    }),
  );

export const spinnerEpics = combineEpics(
  fetchSpinnerEpic,
  templateSpinnerEpic,
  connectDataSpinnerEpic,
  connectToXtmSpinnerEpic,
  customerSpinnerEpic,
  defaultUserLanguagesSpinnerEpic,
  kenticoDataSpinnerEpic,
  kenticoConnectSpinnerEpic,
  projectSpinnerEpic,
);
