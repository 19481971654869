/* eslint-disable id-length */
import { Box } from '@material-ui/core';
import { Dispatch } from '@reduxjs/toolkit';
import XtmConnectAccordion from 'components/Form/Accordion/XtmConnectAccordion/XtmConnectAccordion';
import ButtonContainer from 'components/Form/ButtonContainer/ButtonContainer';
import ConnectedUserFields from 'components/Form/ConnectedFields/ConnectedUserFields';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomCheckbox from 'components/Form/Input/CustomCheckbox';
import CustomField from 'components/Form/Input/CustomField';
import { CustomInputError } from 'components/Form/Input/CustomInput.styled';
import CustomSelect from 'components/Form/Input/CustomSelect';
import FormTitle from 'components/Form/Title/FormTitle';
import { Roles } from 'enums/roles';
import { UserRouteParameterEnum } from 'enums/userRouteParameter';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppDispatch, AppState } from 'store';
import { getAllClients } from 'store/client/client.actions';
import { getAllClientsSelector } from 'store/client/client.selectors';
import { GetProjectsByClientId } from 'store/project/project.actions';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import {
  clearUser,
  clearUserErrors,
  createUser,
  editUser,
  getDefaultUserLanguages,
  getUserById,
  xtmGetTemplates,
} from 'store/user/user.actions';
import { IEditUser, IUserState, XTMGetTemplatesDTO } from 'store/user/user.interface';
import {
  getDefaultUserLanguagesSelector,
  getUserDataSelector,
  getUserErrorsSelector,
  getUserSelector,
  getXtmAuthorizationIdSelector,
  getXtmCustomersSelector,
  getXtmTemplatesSelector,
} from 'store/user/user.selectors';
import { IAutocompleteField } from 'types/shared';
import { checkRoles } from 'utils/checkRoles';
import {
  composeValidators,
  email,
  fetchValidator,
  optionalValidator,
  password,
  required,
} from 'utils/customValidators';
import {
  AuthenticationResult,
  ClientDTO,
  UserDTO,
  XTMCustomerInfoDTO,
  XTMLanguageDTO,
} from 'utils/restApplicationClient';
import { CreateUserDTO, UpdateUserDTO } from 'utils/restApplicationClientTypeOverrides';

interface IStateProps {
  errors: { [key: string]: string | undefined };
  clients: ClientDTO[];
  user?: UserDTO;
  authorizationId?: string;
  currentUser?: AuthenticationResult;
  defaultLanguages?: XTMLanguageDTO[];
  spinner: boolean;
  customers?: Array<XTMCustomerInfoDTO>;
  templates: IUserState['templates'];
}

interface IDispatchProps {
  getAllClients: () => AppDispatch;
  createUser: (payload: CreateUserDTO) => AppDispatch;
  getUserById: (payload: string) => AppDispatch;
  editUser: (payload: IEditUser) => AppDispatch;
  clearUserErrors: () => AppDispatch;
  clearUser: () => AppDispatch;
  getDefaultUserLanguages: () => AppDispatch;
  getProjectsByClientId: (payload: { clientUUID: string }) => AppDispatch;
  getTemplates: (payload: XTMGetTemplatesDTO) => AppDispatch;
}

interface IState {
  submitValues?: UpdateUserDTO | CreateUserDTO | IForm;
  initialCustomers?: Array<IAutocompleteField>;
  initialTemplates?: Array<Array<IAutocompleteField>>;
}

interface IProps {
  native?: boolean;
}

interface IMatchParams {
  id?: string;
  type?: UserRouteParameterEnum;
}

// TODO update once EP is ready
export interface IForm extends Omit<CreateUserDTO, 'xtmCustomers' | 'assignedProjectIds'> {
  xtmCustomers: Array<IAutocompleteField>;
  xtmTemplates: Array<Array<IAutocompleteField>>;
  assignedProjectIds: Array<IAutocompleteField>;
}

type PropType = WithTranslation & RouteComponentProps<IMatchParams> & IProps & IStateProps & IDispatchProps;
export class AddUserContainer extends Component<PropType, IState> {
  constructor(props: PropType) {
    super(props);

    this.state = {
      initialCustomers: undefined,
      initialTemplates: undefined,
      submitValues: undefined,
    };
  }

  componentDidMount(): void {
    const {
      getAllClients,
      getUserById,
      defaultLanguages,
      getDefaultUserLanguages,
      match: {
        params: { id: userId },
      },
    } = this.props;
    clearUserErrors();
    clearUser();
    getAllClients();

    if (!defaultLanguages) {
      getDefaultUserLanguages();
    }

    if (userId) {
      getUserById(userId);
    }
  }

  componentDidUpdate(previousProps: PropType): void {
    const { user, authorizationId, getTemplates, customers, templates } = this.props;

    if (user && authorizationId && previousProps.authorizationId !== authorizationId) {
      const customerIds = user.xtmProperties.xtmCustomers.map(({ xtmCustomerId }) => xtmCustomerId);
      getTemplates({ xtmAuthId: authorizationId, xtmCustomerIds: customerIds });
    }

    if (user !== previousProps.user || customers !== previousProps.customers || templates !== previousProps.templates) {
      this.mapCustomers();
    }
  }

  componentWillUnmount(): void {
    const { clearUser, clearUserErrors } = this.props;

    clearUserErrors();
    clearUser();
  }

  parseClientSelect(rows: ClientDTO[]): Array<{ value: string; name: string }> {
    return rows.map((client) => ({
      value: client.id,
      name: client.clientName,
    }));
  }

  onClientChange =
    (form: FormApi<IForm>) =>
    ({
      target: { value },
    }: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>): void => {
      const { authorizationId, user, getProjectsByClientId } = this.props;
      const shouldConnectedFieldsRender = user && user.xtmProperties && user.xtmProperties.xtmCustomers?.length > 0;

      if ((authorizationId || shouldConnectedFieldsRender) && value) {
        form.change('assignedProjectIds', undefined);
        getProjectsByClientId({ clientUUID: value as string });
      }
    };

  onSubmit = (values: IForm): void => {
    const {
      match: {
        params: { id: userId, type },
      },
    } = this.props;
    const { assignedProjectIds, xtmCustomers, xtmTemplates, xtmAuthorizationId, ...rest } = values;
    const customers = xtmCustomers.map(({ value: customerId }, customerIndex) => ({
      orderNumber: customerIndex,
      xtmCustomerId: Number(customerId),
      xtmTemplates: xtmTemplates[customerIndex].map(({ value: templateId }, templateIndex) => ({
        orderNumber: templateIndex,
        xtmTemplateId: Number(templateId),
      })),
    }));
    const projectIds = assignedProjectIds?.map(({ value }) => value) || [];
    const editValues = { ...rest, assignedProjectIds: projectIds, xtmCustomers: customers };

    this.props.clearUserErrors();
    this.setState({ submitValues: values });
    if (userId && type === UserRouteParameterEnum.edit) {
      this.props.editUser({
        userId,
        updateUser: editValues,
      });
    } else {
      this.props.createUser({
        xtmAuthorizationId,
        ...editValues,
      });
    }
  };

  mapCustomers = (): void => {
    const { user, customers, templates } = this.props;
    const initialTemplates: Array<Array<IAutocompleteField>> = [];

    if (user && customers && templates) {
      const initialCustomers = user.xtmProperties.xtmCustomers

        .map((xtmCustomer) => {
          const customer = customers.find((customer) => customer.xtmCustomerId === xtmCustomer.xtmCustomerId);
          const customerTemplates = templates[xtmCustomer.xtmCustomerId];

          if (customerTemplates) {
            initialTemplates.push(
              xtmCustomer.xtmTemplates
                .map(({ xtmTemplateId }) => {
                  const template = customerTemplates.find(
                    (customerTemplate) => customerTemplate.xtmTemplateId === xtmTemplateId
                  );

                  if (template) {
                    return {
                      label: template.name,
                      value: template.xtmTemplateId.toString(),
                    };
                  }
                  return undefined;
                })
                .filter((value) => value) as Array<IAutocompleteField>
            );
          }

          if (customer) {
            return {
              label: customer.name,
              value: customer.xtmCustomerId.toString(),
            };
          }

          return undefined;
        })
        .filter((value) => value) as Array<IAutocompleteField>;

      this.setState({
        initialCustomers,
        initialTemplates,
      });

      return;
    }

    this.setState({
      initialCustomers: undefined,
      initialTemplates: undefined,
    });
  };

  render(): JSX.Element {
    const {
      match: {
        params: { id: userId, type },
      },
      native,
      errors,
      user,
      authorizationId,
      currentUser,
      spinner,
      t,
    } = this.props;
    const { initialCustomers, initialTemplates } = this.state;

    const shouldConnectedFieldsRender =
      authorizationId && user && user.xtmProperties && user.xtmProperties.xtmCustomers?.length;
    const isClientAdmin = checkRoles([Roles.ADMIN_CLIENT], currentUser?.roles as Roles[]);

    return (
      <FormDiv width={674}>
        <FormTitle text={userId ? 'users.edit' : 'users.add'} />
        <Form
          onSubmit={this.onSubmit}
          initialValues={{
            email: type === 'duplicate' ? '' : user?.email,
            firstName: user?.userSpecification.firstName,
            lastName: user?.userSpecification.lastName,
            clientUUID: isClientAdmin ? this.props.clients[0] && this.props.clients[0].id : user?.client.id,
            status: userId ? user?.status : true,
            interfaceLanguage: user?.interfaceLanguage || 'en-EN',
            daysToDefaultDueDate: user?.userSpecification.daysToDefaultDueDate?.toString(),
            allowDueDateChange: user?.userSpecification.allowDueDateChange,
            notifyWhenTranslationImported: user?.userSpecification.notifyWhenTranslationImported,
            notifyWhenXTMWorkflowChange: user?.userSpecification.notifyWhenXTMWorkflowChange,
            xtmProjectNamePrefix: user?.xtmProperties.xtmProjectNamePrefix,
            daysAfterRemoveArchivedOrDeletedFiles:
              user?.xtmProperties.daysAfterRemoveArchivedOrDeletedFiles?.toString(),
            daysAfterRemoveCancelledJobs: user?.xtmProperties.daysAfterRemoveCancelledJobs?.toString(),
          }}
          mutators={{
            ...arrayMutators,
          }}
          subscription={{
            submitting: true,
            pristine: true,
            submitFailed: true,
            errors: true,
          }}
          render={({ handleSubmit, submitting, pristine, errors: stateErrors, submitFailed, form }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <Box paddingX="17px" marginBottom="135px">
                <Field
                  name="email"
                  component={CustomField}
                  validate={composeValidators([
                    required,
                    email,
                    fetchValidator(errors['email'], this.state?.submitValues?.email),
                  ])}
                  key={errors['email'] ? 'emailError' : 'email'}
                  label="common.email"
                  disableAutocomplete
                />
                <Field
                  name="password"
                  component={CustomField}
                  validate={
                    user && type === UserRouteParameterEnum.edit
                      ? (value: string): null | string => optionalValidator(value, password)
                      : password
                  }
                  label="common.password"
                  textFieldProps={{ type: 'password' }}
                  disableAutocomplete
                />
                <Field
                  name="firstName"
                  component={CustomField}
                  validate={required}
                  label="common.firstName"
                  disableAutocomplete
                />
                <Field
                  name="lastName"
                  component={CustomField}
                  validate={composeValidators([required])}
                  label="common.lastName"
                  disableAutocomplete
                />
                <Field name="clientUUID" validate={required}>
                  {({ input, meta }): JSX.Element => (
                    <CustomSelect
                      label="users.xtm.client"
                      meta={meta}
                      inputProps={input}
                      rows={this.parseClientSelect(this.props.clients)}
                      selectProps={{ native, disabled: isClientAdmin }}
                      onChange={this.onClientChange(form)}
                    />
                  )}
                </Field>
                {stateErrors && submitFailed && <CustomInputError>{t(stateErrors.error)}</CustomInputError>}

                <Field name="interfaceLanguage" validate={required}>
                  {({ input, meta }): JSX.Element => (
                    <CustomSelect
                      label="common.language"
                      meta={meta}
                      inputProps={input}
                      rows={[{ value: 'en-EN', name: 'English (UK)' }]}
                      selectProps={{ native }}
                    />
                  )}
                </Field>
                <Field name="status" type="checkbox" label="common.active" component={CustomCheckbox} />
                <Field name="xtmAuthorizationId" validate={required}>
                  {(inputProps): JSX.Element => <XtmConnectAccordion {...inputProps} />}
                </Field>
                {(authorizationId || shouldConnectedFieldsRender) && (
                  <ConnectedUserFields
                    form={form}
                    authorizationId={authorizationId as string}
                    user={user}
                    initialCustomers={initialCustomers}
                    initialTemplates={initialTemplates}
                  />
                )}
                <ButtonContainer spinner={spinner} backTo="/users" submitting={submitting || pristine} />
              </Box>
            </form>
          )}
        />
      </FormDiv>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  getAllClients: (): AppDispatch => dispatch(getAllClients()),
  createUser: (payload: CreateUserDTO): AppDispatch => dispatch(createUser(payload)),
  editUser: (payload: IEditUser): AppDispatch => dispatch(editUser(payload)),
  getUserById: (payload: string): AppDispatch => dispatch(getUserById(payload)),
  clearUserErrors: (): AppDispatch => dispatch(clearUserErrors()),
  clearUser: (): AppDispatch => dispatch(clearUser()),
  getDefaultUserLanguages: (): AppDispatch => dispatch(getDefaultUserLanguages()),
  getProjectsByClientId: (payload: { clientUUID: string }): AppDispatch => dispatch(GetProjectsByClientId(payload)),
  getTemplates: (payload: XTMGetTemplatesDTO): AppDispatch => dispatch(xtmGetTemplates(payload)),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  clients: getAllClientsSelector(state),
  errors: getUserErrorsSelector(state),
  user: getUserSelector(state),
  authorizationId: getXtmAuthorizationIdSelector(state),
  currentUser: getUserDataSelector(state),
  defaultLanguages: getDefaultUserLanguagesSelector(state),
  spinner: getFetchSpinnerSelector(state),
  customers: getXtmCustomersSelector(state),
  templates: getXtmTemplatesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddUserContainer));
