import CustomTableContainer from 'components/Table/CustomTable';
import TableToolbar from 'components/TableToolbar/TableToolbar';
import { OrderDirection } from 'enums/filter';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { getAdministratorsPageable } from 'store/administrator/administrator.actions';
import { getAdministratorsPageableSelector } from 'store/administrator/administrator.selectors';
import { setPaginationOptions } from 'store/table/table.actions';
import { ITablePaginationOptions } from 'store/table/table.interface';
import { AdministratorDTO, Page } from 'utils/restApplicationClient';

interface IStateProps {
  administratorsPageable?: Page<AdministratorDTO>;
  fetchSpinnerOverride?: boolean;
}

interface IDispatchProps {
  setPaginationOptions: (payload: ITablePaginationOptions) => AppDispatch;
}

const TestTable = CustomTableContainer<AdministratorDTO>();

type IProps = IStateProps & RouteComponentProps & IDispatchProps;

export class AdministratorsContainer extends Component<IProps> {
  headers = [
    { label: 'common.id', id: 'id' },
    { label: 'common.administratorEmail', id: 'email' },
    { label: 'common.client', id: 'client.clientName' },
    { label: 'common.status', id: 'status' },
    { label: 'common.creationDate', id: 'createdAt' },
    { label: 'common.modificationDate', id: 'modifiedAt' },
  ];
  onEdit = (row: AdministratorDTO): void => {
    this.props.history.push(`/administrator/edit/${row.id}`);
  };

  onAdd = (): void => {
    this.props.history.push(`/administrator/add`);
  };

  componentDidMount(): void {
    this.props.setPaginationOptions({
      order: OrderDirection.ASC,
      orderBy: 'email',
    });
  }

  render(): JSX.Element {
    const { administratorsPageable, fetchSpinnerOverride } = this.props;
    return (
      <div>
        <TableToolbar buttonLabelKey="administrator.add" onAdd={this.onAdd} />
        <TestTable
          headers={this.headers}
          onEdit={this.onEdit}
          paginationAction={getAdministratorsPageable}
          pageable={administratorsPageable}
          search={''}
          fetchSpinnerOverride={fetchSpinnerOverride}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  administratorsPageable: getAdministratorsPageableSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setPaginationOptions: (payload: ITablePaginationOptions): AppDispatch =>
    dispatch(setPaginationOptions(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdministratorsContainer));
