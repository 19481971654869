export const ClientActionTypes = {
  GetClientsPageable: 'CLIENT_GET_CLIENTS_PAGEABLE',
  GetClientsPageableSuccess: 'CLIENT_GET_CLIENTS_PAGEABLE_SUCCESS',
  GetClientsPageableError: 'CLIENT_GET_CLIENTS_PAGEABLE_ERROR',
  GetAllClients: 'CLIENT_GET_ALL_CLIENTS',
  GetAllClientsSuccess: 'CLIENT_GET_ALL_CLIENTS_SUCCESS',
  GetAllClientsError: 'CLIENT_GET_ALL_CLIENTS_ERROR',
  CreateClient: 'CLIENT_CREATE_CLIENT',
  CreateClientSuccess: 'CLIENT_CREATE_CLIENT_SUCCESS',
  CreateClientError: 'CLIENT_CREATE_CLIENT_ERROR',
  EditClient: 'CLIENT_EDIT_CLIENT',
  EditClientSuccess: 'CLIENT_EDIT_CLIENT_SUCCESS',
  EditClientError: 'CLIENT_EDIT_CLIENT_ERROR',
  GetClientById: 'CLIENT_GET_CLIENT_BY_ID',
  GetClientByIdSuccess: 'CLIENT_GET_CLIENT_BY_ID_SUCCESS',
  GetClientByIdError: 'CLIENT_GET_CLIENT_BY_ID_ERROR',
  ClearClient: 'CLIENT_CLEAR_CLIENT',
  ClearClientErrors: 'CLIENT_CLEAR_ERRORS',
};
