import { createAction } from '@reduxjs/toolkit';
import { ErrorPayload } from 'store/error/error.epic';
import { IEditAdministrator, IPaginationOptions } from 'types/api';
import { AdministratorDTO, Page } from 'utils/restApplicationClient';
import { CreateAdministratorDTO } from 'utils/restApplicationClientTypeOverrides';
import { AdministratorActionTypes } from './administrator.actions.types';

export const createAdministrator = createAction<CreateAdministratorDTO>(AdministratorActionTypes.CreateAdministrator);
export const createAdministratorError = createAction<ErrorPayload>(AdministratorActionTypes.CreateAdministratorError);
export const createAdministratorSuccess = createAction(AdministratorActionTypes.CreateAdministratorSuccess);

export const clearAdministratorErrors = createAction(AdministratorActionTypes.ClearAdministratorErrors);

export const getAdministratorsPageable = createAction<IPaginationOptions>(
  AdministratorActionTypes.GetAdministatorsPageable,
);
export const getAdministratorsPageableSuccess = createAction<Page<AdministratorDTO>>(
  AdministratorActionTypes.GetAdministratorsPageableSuccess,
);
export const getAdministratorsPageableError = createAction<ErrorPayload>(
  AdministratorActionTypes.GetAdministratorsPageableError,
);

export const getAdministratorById = createAction<string>(AdministratorActionTypes.GetAdministratorById);
export const getAdministratorByIdSuccess = createAction<AdministratorDTO>(
  AdministratorActionTypes.GetAdministratorByIdSuccess,
);
export const getAdministratorByIdError = createAction<ErrorPayload>(AdministratorActionTypes.GetAdministratorByIdError);

export const clearAdministrator = createAction(AdministratorActionTypes.ClearAdministrator);

export const editAdministrator = createAction<IEditAdministrator>(AdministratorActionTypes.EditAdministrator);

export const editAdministratorSuccess = createAction(AdministratorActionTypes.EditAdministratorSuccess);

export const editAdministratorError = createAction<ErrorPayload>(AdministratorActionTypes.EditAdministratorError);
