import styled from '@emotion/styled';
import { Select, TableRow } from '@material-ui/core';

export const CustomPaginationContainer = styled.td`
  padding: 6px 0;
`;

export const CustomTableRow = styled(TableRow)`
  thead > & td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;

export const CustomPaginationActionsContainer = styled.div`
  display: flex;
  padding: 0 8px;
  align-items: center;

  .MuiPaginationItem-textSecondary.Mui-selected {
    background-color: #eff3f6;
    color: #10a4e4;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

export const CustomPaginationSelect = styled(Select)`
  .MuiSelect-root {
    padding: 0px 24px 0px 8px !important;
  }
  .MuiSelect-icon {
    right: 0 !important;
  }
  height: 23px !important;
  border-radius: 0 !important;
  margin: 0 8px;
`;
