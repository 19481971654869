/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Button, CircularProgress } from '@material-ui/core';
import { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IProps {
  submitting: boolean;
  backTo: string;
  spinner: boolean;
}

const ButtonContainerDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #dddddd;
  justify-content: center;
  height: 50px;
  align-items: center;
  z-index: 2;
  background-color: #fff;

  & button {
    margin-right: 8px;
    font-size: 13px;
    font-weight: 500;
  }
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
`;

class ButtonContainer extends PureComponent<WithTranslation & IProps> {
  render(): JSX.Element {
    const { t, submitting, backTo, spinner } = this.props;

    return (
      <ButtonContainerDiv>
        <ButtonLink to={backTo} style={{ textDecoration: 'none' }}>
          <Button variant="outlined">{t('common.cancel')}</Button>
        </ButtonLink>
        <Button variant="contained" color="secondary" disabled={submitting || spinner} type="submit">
          {spinner ? <CircularProgress color="secondary" size={20} /> : t('common.save')}
        </Button>
      </ButtonContainerDiv>
    );
  }
}

export default withTranslation()(ButtonContainer);
