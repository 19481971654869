import { css } from '@emotion/core';

export const iconButtonLabelStyle = {
  button: css`
    text-transform: none !important;
    padding: 0px !important;
    color: #555555 !important;
    font-weight: 500 !important;
  `,
};
