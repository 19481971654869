import CustomTableContainer from 'components/Table/CustomTable';
import TableToolbar from 'components/TableToolbar/TableToolbar';
import { OrderDirection } from 'enums/filter';
import { UserRouteParameterEnum } from 'enums/userRouteParameter';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { setPaginationOptions } from 'store/table/table.actions';
import { ITablePaginationOptions } from 'store/table/table.interface';
import { usersPageable } from 'store/user/user.actions';
import { Page, UserOverviewDTO } from 'utils/restApplicationClient';
import { getUsersPageableSelector } from '../../store/user/users.selectors';

interface IStateProps {
  users?: Page<UserOverviewDTO>;
}

interface IDispatchProps {
  setPaginationOptions: (payload: ITablePaginationOptions) => AppDispatch;
}

const UserTable = CustomTableContainer<UserOverviewDTO>();

type IProps = IStateProps & RouteComponentProps & IDispatchProps;

class UsersContainer extends Component<IProps> {
  headers = [
    { label: 'common.id', id: 'id' },
    { label: 'common.userEmail', id: 'email' },
    { label: 'common.client', id: 'client.clientName' },
    { label: 'common.status', id: 'status' },
    { label: 'common.creationDate', id: 'createdAt' },
    { label: 'common.modificationDate', id: 'modifiedAt' },
  ];
  onEdit = (row: UserOverviewDTO): void => {
    this.props.history.push(`user/${UserRouteParameterEnum.edit}/${row.id}`);
  };

  onDuplicate = (row: UserOverviewDTO): void => {
    this.props.history.push(`user/${UserRouteParameterEnum.duplicate}/${row.id}`);
  };

  onAdd = (): void => {
    this.props.history.push('user/add');
  };

  componentDidMount(): void {
    this.props.setPaginationOptions({
      order: OrderDirection.ASC,
      orderBy: 'email',
    });
  }

  render(): JSX.Element {
    const { users } = this.props;

    return (
      <div>
        <TableToolbar buttonLabelKey="users.add" onAdd={this.onAdd} />
        <UserTable
          headers={this.headers}
          onEdit={this.onEdit}
          paginationAction={usersPageable}
          pageable={users}
          search={''}
          onDuplicate={this.onDuplicate}
          displayActionsLabel
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  users: getUsersPageableSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setPaginationOptions: (payload: ITablePaginationOptions): AppDispatch =>
    dispatch(setPaginationOptions(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersContainer));
