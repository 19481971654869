import { css, SerializedStyles } from '@emotion/core';

export const mainStyles = (): SerializedStyles => css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  html {
    display: table;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    display: table-cell;
    width: 100%;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-family: Lato, arial, sans-serif;
    text-rendering: optimizeLegibility;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    font-kerning: auto;
    background: #eff3f7;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  a:link {
    color: #0c93e4;
  }

  a:visited {
    color: #0c93e4;
  }

  @supports (-moz-appearance: none) {
    #root {
      height: 100vh;
    }
  }
`;
