/** @jsx jsx */
import { jsx } from '@emotion/core';
import { AppBar } from '@material-ui/core';
import Help from 'icons/Help';
import Logout from 'icons/Logout';
import ReleaseNotes from 'icons/ReleaseNotes';
import { Dispatch, PureComponent, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppDispatch } from 'store';
import { logoutUser } from 'store/user/user.actions';
import { tokenStorageKey } from 'utils/axiosInstance';
import { getAllEnvironment } from 'utils/envHandler';
import loginHeaderStyle, { HeaderButton, HeaderLogoutButton } from './LoginHeader.style';

interface IProps {
  children?: ReactNode;
}

interface IDispatchProps {
  logout: () => void;
}

type PropType = IProps & WithTranslation & IDispatchProps;

class LoginHeader extends PureComponent<PropType> {
  handleOpenLink = (url: string) => (): void => {
    window.open(url, '_blank');
  };

  handleLogout = (): void => {
    this.props.logout();
  };

  render(): JSX.Element {
    const { t } = this.props;
    const { REACT_APP_HELP_URL, REACT_APP_CHANGELOG_URL } = getAllEnvironment();
    const token = localStorage.getItem(tokenStorageKey);

    return (
      <AppBar position="relative">
        <div css={loginHeaderStyle.logoContainer}>
          <img css={loginHeaderStyle.img} src="/logo.svg" />
          <span css={loginHeaderStyle.title}>
            {t('common.title')} {t('common.version', { version: process.env.REACT_APP_VERSION })}
          </span>
        </div>
        <div>
          {token && REACT_APP_CHANGELOG_URL && (
            <HeaderButton
              disableTouchRipple
              onClick={this.handleOpenLink(REACT_APP_CHANGELOG_URL)}
            >
              <ReleaseNotes />
              {t('common.releaseNotes')}
            </HeaderButton>
          )}
          {token && REACT_APP_HELP_URL && (
            <HeaderButton disableTouchRipple onClick={this.handleOpenLink(REACT_APP_HELP_URL)}>
              <Help />
              {t('common.help')}
            </HeaderButton>
          )}
          {token && (
            <HeaderLogoutButton disableTouchRipple onClick={this.handleLogout}>
              <Logout />
              {t('common.logout')}
            </HeaderLogoutButton>
          )}
        </div>
      </AppBar>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  logout: (): void => dispatch(logoutUser()),
});

export default connect(null, mapDispatchToProps)(withTranslation()(LoginHeader));
