import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { clearUser, getUsersByClientId } from 'store/user/user.actions';
import {
  addProjectError,
  clearProjectErrors,
  connectToKentico,
  connectToKenticoError,
  editProjectError,
  GetProjectsByClientIdSuccess,
  getProjectsPageableSuccess,
  connectToKenticoSuccess,
  getProjectByIdSuccess,
  clearProjectFormAll,
  allFormObservers,
  updateFormProject,
} from './project.actions';
import { IProjectState } from './project.interface';

const initState: IProjectState = {
  errors: {},
  projectsPageable: undefined,
  project: undefined,
  connectedProjectId: '',
  clientProjects: [],
  selectedClientId: '',
  form: {
    users: [],
    dataFetchedCount: 0,
    webhooks: [],
    workflowSteps: [],
    contentTypes: [],
    contentElements: [],
    xtmLanguages: [],
    kenticoLanguages: [],
    kontentProjectId: '',
    project: undefined,
  }
};

export const projectReducer = createReducer(initState, (builder) =>
  builder
    .addCase(getProjectsPageableSuccess, (state, action) => {
      state.projectsPageable = action.payload;
    })
    .addCase(clearProjectErrors, (state) => {
      state.errors = {};
    })
    .addCase(getUsersByClientId, (state, { payload }) => {
      state.selectedClientId = payload;
      state.form.project && (state.form.project.clientUUID = payload)
    })
    .addCase(connectToKentico, (state, { payload: { kontentProjectId } }) => {
      state.connectedProjectId = kontentProjectId;
    })
    .addCase(connectToKenticoError, (state) => {
      state.errors.kenticoConnect = 'projects.errors.connect';
      state.connectedProjectId = '';
      state.form.kontentProjectId = '';
    })
    .addCase(GetProjectsByClientIdSuccess, (state, { payload }) => {
      state.clientProjects = payload;
    })
    .addCase(clearUser, (state) => {
      state.clientProjects = [];
    })
    .addCase(connectToKenticoSuccess, (state, { payload }) => {
      state.form.kontentProjectId = payload.toString() || ''
    })
    .addCase(getProjectByIdSuccess, (state, { payload }) => {
      state.form.project = payload;
    })
    .addCase(clearProjectFormAll, (state) => {
      state.form = initState.form
    })
    .addCase(updateFormProject, (state, { payload }) => {
      state.form.project = payload
    })
    .addCase(allFormObservers, (state, { payload }) => {
      state.form = {...state.form, ...payload}
    })
    .addMatcher(
      isAnyOf(addProjectError, editProjectError),
      (
        state,
        {
          payload: {
            error: { data, status },
          },
        },
      ) => {
        const { errorMessage, errorCode } = Array.isArray(data) ? data[0] : data;
        if (!(errorCode === 'errormessage.kontent.projectId')) {
          const isProjectIdError = errorMessage.includes('Kentico Kontent id') && status === 409;

          state.errors = isProjectIdError
            ? { kontentProjectId: 'projects.errors.duplicateId' }
            : { projectName: 'projects.errors.duplicateName' };
        }
      },
    ),
);
