import { Box, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { PureComponent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  CustomPaginationActionsContainer,
  CustomPaginationContainer,
  CustomPaginationSelect,
  CustomTableRow,
} from './Pagination.styled';
import PaginationTransLabel from './PaginationTransLabel';

export interface IPaginationProps {
  onChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
  onChangeRowsPerPage: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void;
  page: number;
  rowsPerPage: number;
  columns: number;
  count: number;
  nativeSelect?: boolean;
}

class CustomPagination extends PureComponent<IPaginationProps & WithTranslation> {
  getPaginationLabelDisplayedRows({ to, from, count }: { to: number; from: number; count: number }): JSX.Element {
    return <PaginationTransLabel to={to} from={from} count={count} />;
  }

  render(): JSX.Element {
    const { count, t, onChangePage, onChangeRowsPerPage, page, rowsPerPage, columns } = this.props;
    const pages = Math.ceil(count / rowsPerPage);
    return (
      <CustomTableRow>
        <CustomPaginationContainer colSpan={columns + 1}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" paddingX="8px">
              <Box fontSize="12px" fontWeight="400" color="#333">
                {t('common.show')}
              </Box>
              <CustomPaginationSelect value={rowsPerPage} onChange={onChangeRowsPerPage} variant="outlined">
                {[5, 10, 25, 50].map((rowOption, index) => (
                  <MenuItem key={index} value={rowOption}>
                    {rowOption}
                  </MenuItem>
                ))}
              </CustomPaginationSelect>
              <Box fontSize="12px" fontWeight="400" color="#333">
                {t('common.items')}
              </Box>
            </Box>
            <CustomPaginationActionsContainer>
              <Pagination size="small" count={pages} color="secondary" page={page + 1} onChange={onChangePage} />
            </CustomPaginationActionsContainer>
          </Box>
        </CustomPaginationContainer>
      </CustomTableRow>
    );
  }
}

export default withTranslation()(CustomPagination);
