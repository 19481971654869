/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Button, Typography } from '@material-ui/core';
import loginHeaderStyle from 'components/LoginHeader/LoginHeader.style';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppDispatch, AppState } from 'store';
import { logoutUser, sessionExpired } from 'store/user/user.actions';
import { getSessionExpiredSeletor } from 'store/user/user.selectors';
import { ModalDropdown, ModalLogoBar, ModalTextDiv, ModalWindow } from './SessionExpiredModal.styled';

interface IStateProps {
  sessionExpired: boolean;
}

interface IDispatchProps {
  setSessionExpired: (payload: boolean) => AppDispatch;
  logout: () => AppDispatch;
}

type PropType = WithTranslation & IDispatchProps & IStateProps;

class SessionExpiredModal extends Component<PropType> {
  handleClick = (): void => {
    const { setSessionExpired, logout } = this.props;
    setSessionExpired(false);
    logout();
  };

  render(): JSX.Element {
    const { t, sessionExpired } = this.props;
    const visibility = css`
      visibility: ${sessionExpired ? 'visible' : 'hidden'};
    `;
    return (
      <ModalDropdown css={visibility}>
        <ModalWindow>
          <ModalLogoBar>
            <img css={loginHeaderStyle.img} src="/logo.svg" />
          </ModalLogoBar>
          <ModalTextDiv>
            <Typography variant="body1">{t('auth.sessionExpired.text1')}</Typography>
            <Typography variant="body1">{t('auth.sessionExpired.text2')}</Typography>
          </ModalTextDiv>
          <Button variant="contained" color="secondary" onClick={this.handleClick}>
            {t('auth.login')}
          </Button>
        </ModalWindow>
      </ModalDropdown>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  sessionExpired: getSessionExpiredSeletor(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  setSessionExpired: (payload: boolean): AppDispatch => dispatch(sessionExpired(payload)),
  logout: (): AppDispatch => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SessionExpiredModal));
