export const UserActionTypes = {
  Login: 'USER_LOGIN',
  LoginSuccess: 'USER_LOGIN_SUCCESS',
  LoginError: 'USER_LOGIN_ERROR',
  Logout: 'USER_LOGOUT',
  LogoutSuccess: 'USER_LOGOUT_SUCCESS',
  LogoutError: 'USER_LOGOUT_ERROR',
  ResetPassword: 'USER_RESET_PASSWORD',
  ResetPasswordSuccess: 'USER_RESET_PASSWORD_SUCCESS',
  ResetPasswordError: 'USER_RESET_PASSWORD_ERROR',
  ResetUserPassword: 'USER_RESET_USER_PASSWORD',
  ResetUserPasswordSuccess: 'USER_RESET_USER_PASSWORD_SUCCESS',
  ResetUserPasswordError: 'USER_RESET_USER_PASSWORD_ERROR',
  VerifyToken: 'USER_VERIFY_TOKEN',
  VerifyTokenSuccess: 'USER_VERIFY_TOKEN_SUCCESS',
  VerifyTokenError: 'USER_VERIFY_TOKEN_ERROR',
  RefreshTokenSuccess: 'REFRESH_USER_TOKEN_SUCCESS',
  ChangePasswordSuccess: 'CHANGE_PASSWORD_SUCCESS',
  ChangePasswordError: 'CHANGE_PASSWORD_ERROR',
  ChangePassword: 'CHANGE_PASSWORD',
  ChangeUserPasswordSuccess: 'CHANGE_USER_PASSWORD_SUCCESS',
  ChangeUserPasswordError: 'CHANGE_USER_PASSWORD_ERROR',
  ChangeUserPassword: 'CHANGE_USER_PASSWORD',
  verifyPassTokenSuccess: 'VERIFY_PASS_TOKEN_SUCCESS',
  verifyPassTokenError: 'VERIFY_PASS_TOKEN_ERROR',
  verifyPassToken: 'VERIFY_PASS_TOKEN',
  GetAllUsers: 'GET_ALL_USERS',
  GetAllUsersError: 'GET_ALL_USERS_ERROR',
  GetAllUsersSuccess: 'GET_ALL_USERS_SUCCESS',
  CreateUser: 'CREATE_USER',
  CreateUserSuccess: 'CREATE_USER_SUCCESS',
  CreateUserError: 'CREATE_USER_ERROR',
  UsersPageable: 'USERS-PAGEABLE',
  UsersPageableError: 'USERS_PAGEABLE_ERROR',
  UsersPageableSuccess: 'USERS_PAGEABLE_SUCCESS',
  EditUser: 'EDIT_USER',
  EditUserSuccess: 'EDIT_USER_SUCCESS',
  EditUserError: 'EDIT_USER_ERROR',
  GetUserById: 'GET_USER_BY_ID',
  GetUserByIdSuccess: 'GET_USER_BY_ID_SUCCESS',
  GetUserByIdError: 'GET_USER_BY_ID_ERROR',
  ClearUser: 'CLEAR_USER',
  ClearUserErrors: 'CLEAR_USER_ERROR',
  xtmConnect: 'USER_XTM_CONNECT',
  xtmConnectSuccess: 'USER_XTM_CONNECT_SUCCESS',
  xtmConnectError: 'USER_XTM_CONNECT_ERROR',
  xtmGetCustomers: 'USER_XTM_GET_CUSTOMERS',
  xtmGetCustomersError: 'USER_XTM_GET_CUSTOMERS_ERROR',
  xtmGetCustomersSuccess: 'USER_XTM_GET_CUSTOMERS_SUCCESS',
  xtmGetTemplates: 'USER_XTM_GET_TEMPLATES',
  xtmGetTemplatesSuccess: 'USER_XTM_GET_TEMPLATES_SUCCESS',
  xtmGetTemplatesError: 'USER_XTM_GET_TEMPLATES_ERROR',
  xtmGetCustomersById: 'USER_XTM_GET_CUSTOMERS_BY_ID',
  xtmGetCustomersByIdSuccess: 'USER_XTM_GET_CUSTOMERS_BY_ID_SUCCESS',
  xtmGetCustomersByIdError: 'USER_XTM_GET_CUSTOMERS_BY_ID_ERROR',
  xtmGetConnectData: 'USER_XTM_GET_CONNECT_DATA',
  xtmGetConnectDataSuccess: 'USER_XTM_GET_CONNECT_DATA_SUCCESS',
  xtmGetConnectDataError: 'USER_XTM_GET_CONNECT_DATA_ERROR',
  xtmConnectUser: 'USER_CONNECT_TO_XTM_USER',
  xtmConnectUserError: 'USER_CONNECT_TO_XTM_USER_ERROR',
  xtmConnectUserSuccess: 'USER_CONNECT_TO_XTM_USER_SUCCESS',
  clearTemplates: 'USER_CLEAR_XTM_TEMPLATES',
  getDefaultUserLanguage: 'USER_GET_DEFAULT_LANGUAGE',
  getDefaultUserLanguageSuccess: 'USER_GET_DEFAULT_LANGUAGE_SUCCESS',
  getDefaultUserLanguageError: 'USER_GET_DEFAULT_LANGUAGE_ERROR',
  clearDefaultUserLanguage: 'USER_CLEAR_DEFAULT_LANGUAGE',
  sessionExpired: 'USER_SESSION_EXPIRED',
  showSessionExpired: 'USER_SHOW_SESSION_EXPIRED',
  hideSessionExpired: 'USER_HIDE_SESSION_EXPIRED',
  getUsersByClientId: 'GET_USERS_BT_CLIENT_ID',
  getUsersByClientIdSuccess: 'GET_USERS_BT_CLIENT_ID_SUCCESS',
  getUsersByClientIdError: 'GET_USERS_BT_CLIENT_ID_ERROR',
};

export interface IRemindPasswordPayload {
  email: string;
}
