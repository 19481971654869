export const SpinnerActionTypes = {
  setFetchSpinner: 'SPINNER_SET_LOADER',
  setTemplateSpinner: 'SPINNER_SET_TEMPLATE_LOADER',
  setCustomerSpinner: 'SPINNER_SET_CUSTOMER_LOADER',
  setConnectDataSpinner: 'SPINNER_SET_CONNECT_DATA_LOADER',
  setConnectToXtmSpinner: 'SPINNER_SET_CONNECT_TO_XTM',
  setConnectToCMSSpinner: 'SPINNER_SET_CONNECT_TO_CMS',
  setDefaultUserLanguagesSpinner: 'SPINNER_SET_DEFAULT_USER_LANGUAGES',
  setProjectSpinner: 'SET_PROJECT_SPINNER',
  setKenticoDataSpinner: 'SET_KENTICO_DATA_SPINNER',
  setKenticoConnectSpinner: 'SET_KENTICO_CONNECT_SPINNER',
};
