import { createAction } from '@reduxjs/toolkit';
import { ErrorPayload } from 'store/error/error.epic';
import {
  AuthenticationResult,
  Page,
  RefreshTokenResult,
  UserDTO,
  UserOverviewDTO,
  XTMConnectDTO,
  XTMCustomerInfoDTO,
  XTMLanguageDTO,
  XTMTemplateDTO,
} from 'utils/restApplicationClient';
import {
  AuthenticationRequest,
  ChangePasswordDTO,
  CreateUserDTO,
  XTMConnectionParametersDTO,
  XTMConnectRequest,
} from 'utils/restApplicationClientTypeOverrides';
import { IRemindPasswordPayload, UserActionTypes } from './user.actions.types';
import { IEditUser, IUsersPageable, XTMGetTemplatesDTO } from './user.interface';

export const verifyPassToken = createAction<string>(UserActionTypes.verifyPassToken);
export const verifyPassTokenSuccess = createAction(UserActionTypes.verifyPassTokenSuccess);
export const verifyPassTokenError = createAction<ErrorPayload>(UserActionTypes.verifyPassTokenError);
export const changePassword = createAction<ChangePasswordDTO>(UserActionTypes.ChangePassword);
export const changePasswordSuccess = createAction(UserActionTypes.ChangePasswordSuccess);
export const changePasswordError = createAction<ErrorPayload>(UserActionTypes.ChangePasswordError);
export const changeUserPassword = createAction<ChangePasswordDTO>(UserActionTypes.ChangeUserPassword);
export const changeUserPasswordSuccess = createAction(UserActionTypes.ChangeUserPasswordSuccess);
export const changeUserPasswordError = createAction<ErrorPayload>(UserActionTypes.ChangeUserPasswordError);
export const loginUser = createAction<AuthenticationRequest>(UserActionTypes.Login);
export const loginUserSuccess = createAction<AuthenticationResult>(UserActionTypes.LoginSuccess);
export const loginUserError = createAction<ErrorPayload>(UserActionTypes.LoginError);

export const logoutUser = createAction(UserActionTypes.Logout);
export const logoutUserSuccess = createAction(UserActionTypes.LogoutSuccess);
export const logoutUserError = createAction<ErrorPayload>(UserActionTypes.LogoutError);

export const getUsersByClientId = createAction<string>(UserActionTypes.getUsersByClientId);
export const getUsersByClientIdSuccess = createAction<UserOverviewDTO[]>(UserActionTypes.getUsersByClientIdSuccess);
export const getUsersByClientIdError = createAction<ErrorPayload>(UserActionTypes.getUsersByClientIdError);

export const resetPassword = createAction<IRemindPasswordPayload>(UserActionTypes.ResetPassword);
export const resetPasswordSuccess = createAction(UserActionTypes.ResetPasswordSuccess);
export const resetPasswordError = createAction<ErrorPayload>(UserActionTypes.ResetPasswordError);

export const resetUserPassword = createAction<IRemindPasswordPayload>(UserActionTypes.ResetUserPassword);
export const resetUserPasswordSuccess = createAction(UserActionTypes.ResetUserPasswordSuccess);
export const resetUserPasswordError = createAction<ErrorPayload>(UserActionTypes.ResetUserPasswordError);

export const verifyToken = createAction(UserActionTypes.VerifyToken);
export const verifyTokenSuccess = createAction<{ ttl: number }>(UserActionTypes.VerifyTokenSuccess);
export const verifyTokenError = createAction<ErrorPayload>(UserActionTypes.VerifyTokenError);
export const refreshTokenSuccess = createAction<RefreshTokenResult>(UserActionTypes.RefreshTokenSuccess);

export const getAllUsers = createAction(UserActionTypes.GetAllUsers);
export const getAllUsersError = createAction<ErrorPayload>(UserActionTypes.GetAllUsersError);
export const getAllUsersSuccess = createAction<UserDTO[]>(UserActionTypes.GetAllUsersSuccess);
export const createUser = createAction<CreateUserDTO>(UserActionTypes.CreateUser);
export const createUserSuccess = createAction(UserActionTypes.CreateUserSuccess);
export const createUserError = createAction<ErrorPayload>(UserActionTypes.CreateUserError);

export const usersPageable = createAction<IUsersPageable>(UserActionTypes.UsersPageable);
export const usersPageableSuccess = createAction<Page<UserOverviewDTO>>(UserActionTypes.UsersPageableSuccess);
export const usersPageableError = createAction<ErrorPayload>(UserActionTypes.UsersPageableError);
export const editUser = createAction<IEditUser>(UserActionTypes.EditUser);
export const editUserSuccess = createAction(UserActionTypes.EditUserSuccess);
export const editUserError = createAction<ErrorPayload>(UserActionTypes.EditUserError);

export const getUserById = createAction<string>(UserActionTypes.GetUserById);
export const getUserByIdSuccess = createAction<UserDTO>(UserActionTypes.GetUserByIdSuccess);
export const getUserByIdError = createAction<ErrorPayload>(UserActionTypes.GetUserByIdError);

export const clearUser = createAction(UserActionTypes.ClearUser);

export const clearUserErrors = createAction(UserActionTypes.ClearUserErrors);

export const xtmConnect = createAction<XTMConnectRequest>(UserActionTypes.xtmConnect);

export const xtmConnectError = createAction<ErrorPayload>(UserActionTypes.xtmConnectError);

export const xtmConnectSuccess = createAction<XTMConnectDTO>(UserActionTypes.xtmConnectSuccess);

export const xtmConnectUser = createAction<{
  userId: string;
  xtmParameters: XTMConnectRequest;
}>(UserActionTypes.xtmConnectUser);

export const xtmConnectUserError = createAction<ErrorPayload>(UserActionTypes.xtmConnectUserError);

export const xtmConnectUserSuccess = createAction<XTMConnectDTO>(UserActionTypes.xtmConnectUserSuccess);

export const xtmGetCustomers = createAction<XTMConnectDTO>(UserActionTypes.xtmGetCustomers);

export const xtmGetCustomersError = createAction<ErrorPayload>(UserActionTypes.xtmGetCustomersError);

export const xtmGetCustomersSuccess = createAction<Array<XTMCustomerInfoDTO>>(UserActionTypes.xtmGetCustomersSuccess);

export const xtmGetTemplates = createAction<XTMGetTemplatesDTO>(UserActionTypes.xtmGetTemplates);

export const xtmGetTemplatesError = createAction<ErrorPayload>(UserActionTypes.xtmGetTemplatesError);

export const xtmGetTemplatesSuccess = createAction<{ [customerId: string]: XTMTemplateDTO[] }>(
  UserActionTypes.xtmGetTemplatesSuccess,
);

export const xtmGetCustomersById = createAction<string>(UserActionTypes.xtmGetCustomersById);

export const xtmGetCustomersByIdError = createAction<ErrorPayload>(UserActionTypes.xtmGetCustomersByIdError);

export const xtmGetCustomersByIdSuccess = createAction<Array<XTMCustomerInfoDTO>>(
  UserActionTypes.xtmGetCustomersByIdSuccess,
);

export const xtmGetConnectData = createAction<string>(UserActionTypes.xtmGetConnectData);

export const xtmGetConnectDataError = createAction<ErrorPayload>(UserActionTypes.xtmGetConnectDataError);

export const xtmGetConnectDataSuccess = createAction<XTMConnectionParametersDTO>(
  UserActionTypes.xtmGetConnectDataSuccess,
);

export const getDefaultUserLanguages = createAction(UserActionTypes.getDefaultUserLanguage);
export const getDefaultUserLanguagesSuccess = createAction<XTMLanguageDTO[]>(
  UserActionTypes.getDefaultUserLanguageSuccess,
);
export const getDefaultUserLanguagesError = createAction<ErrorPayload>(UserActionTypes.getDefaultUserLanguageError);
export const clearDefaultUserLanguages = createAction(UserActionTypes.clearDefaultUserLanguage);

export const clearXtmTemplates = createAction(UserActionTypes.clearTemplates);

export const sessionExpired = createAction<boolean>(UserActionTypes.sessionExpired);

export const showSessionExpired = createAction(UserActionTypes.showSessionExpired);

export const hideSessionExpired = createAction(UserActionTypes.hideSessionExpired);
