import React from 'react';

export default function XTM(): JSX.Element {
  return (
    <svg width="38" height="11" viewBox="0 0 38 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.1891 0.132935H13.4549C12.6753 0.132935 12.041 0.767267 12.041 1.54681C12.041 2.32635 12.6753 2.96068 13.4549 2.96068H15.9081V9.23523C15.9081 10.0148 16.5425 10.6491 17.322 10.6491C18.1016 10.6491 18.7359 10.0148 18.7359 9.23523V2.96068H21.1891C21.9687 2.96068 22.603 2.32635 22.603 1.54681C22.603 0.767267 21.9687 0.132935 21.1891 0.132935Z"
        fill="white"
      />
      <path
        d="M10.7876 0.499735C10.2373 -0.05053 9.34314 -0.05053 8.78523 0.499735L5.91927 3.37334L3.04567 0.499735C2.4954 -0.05053 1.60122 -0.05053 1.04331 0.499735C0.493049 1.05 0.493049 1.94418 1.04331 2.50209L3.90928 5.36805L1.04331 8.23401C0.493049 8.78427 0.493049 9.67845 1.04331 10.2364C1.31845 10.5115 1.67765 10.6491 2.04449 10.6491C2.40369 10.6491 2.77053 10.5115 3.04567 10.2364L5.91163 7.3704L8.77759 10.2364C9.05272 10.5115 9.41192 10.6491 9.77876 10.6491C10.138 10.6491 10.5048 10.5115 10.7799 10.2364C11.3302 9.6861 11.3302 8.79192 10.7799 8.23401L7.91398 5.36805L10.7799 2.50209C11.3378 1.95182 11.3378 1.05764 10.7876 0.499735Z"
        fill="white"
      />
      <path
        d="M33.3332 0.0870361C32.2173 0.0870361 31.2009 0.51502 30.429 1.21049C29.6647 0.51502 28.6406 0.0870361 27.5248 0.0870361C25.148 0.0870361 23.2068 2.0206 23.2068 4.40508V9.24283C23.2068 10.0224 23.8411 10.6567 24.6207 10.6567C25.4002 10.6567 26.0345 10.0224 26.0345 9.24283V4.40508C26.0345 3.58733 26.6994 2.91478 27.5248 2.91478C28.3426 2.91478 29.0151 3.57969 29.0151 4.40508V9.24283C29.0151 10.0224 29.6494 10.6567 30.429 10.6567C31.2085 10.6567 31.8429 10.0224 31.8429 9.24283V4.40508C31.8429 3.58733 32.5078 2.91478 33.3332 2.91478C34.1585 2.91478 34.8234 3.57969 34.8234 4.40508V9.24283C34.8234 10.0224 35.4578 10.6567 36.2373 10.6567C37.0169 10.6567 37.6512 10.0224 37.6512 9.24283V4.40508C37.6512 2.02825 35.71 0.0870361 33.3332 0.0870361Z"
        fill="white"
      />
    </svg>
  );
}
