/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box } from '@material-ui/core';
import { Dispatch } from '@reduxjs/toolkit';
import ButtonContainer from 'components/Form/ButtonContainer/ButtonContainer';
import { EditData } from 'components/Form/CilentData/EditData';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomCheckbox from 'components/Form/Input/CustomCheckbox';
import CustomField from 'components/Form/Input/CustomField';
import { CustomInputError } from 'components/Form/Input/CustomInput.styled';
import FormTitle from 'components/Form/Title/FormTitle';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { AppDispatch, AppState } from 'store';
import { clearClient, clearClientErrors, createClient, editClient, getClientById } from 'store/client/client.actions';
import { getClientErrorsSelector, getClientSelector } from 'store/client/client.selectors';
import { getFetchSpinnerSelector } from 'store/spinner/spinner.selectors';
import { IEditClient } from 'types/api';
import { composeValidators, fetchValidator, required } from 'utils/customValidators';
import { ClientDTO, CreateClientDTO } from 'utils/restApplicationClient';
import { addClientContainerStyles } from './addClientContainer.style';

interface IStateProps {
  errors: { [key: string]: string | undefined };
  client: ClientDTO | undefined;
  spinner: boolean;
}

interface IDispatchProps {
  createClient: (payload: CreateClientDTO) => AppDispatch;
  editClient: (payload: IEditClient) => AppDispatch;
  getClientById: (payload: string) => AppDispatch;
  clearClient: () => AppDispatch;
  clearClientErrors: () => AppDispatch;
}

// eslint-disable-next-line max-lines-per-function
export const AddClientForm = (props: IStateProps & IDispatchProps): JSX.Element => {
  const {
    params: { id: clientId },
  } = useRouteMatch<{ id: string }>();

  const { t } = useTranslation();
  const { errors, createClient, editClient } = props;
  const [submitValues, setSubmitValues] = useState<{
    clientName?: string;
    status?: boolean;
  }>({
    clientName: undefined,
    status: undefined,
  });

  const { client, spinner } = props;

  const onSubmit = (values: CreateClientDTO): void => {
    props.clearClientErrors();
    setSubmitValues(values);

    if (clientId) {
      editClient({ clientId, updateClient: values });
    } else {
      createClient(values);
    }
  };

  useEffect(() => {
    if (clientId) {
      props.getClientById(clientId);
    }
    return (): void => {
      props.clearClient();
      props.clearClientErrors();
    };
  }, []);

  return (
    <FormDiv width={674}>
      <FormTitle text={clientId ? 'client.edit' : 'client.add'} />
      <Form
        initialValues={{
          clientName: client && client.clientName,
          status: clientId ? client && client.status : true,
        }}
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          pristine: true,
          submitFailed: true,
          errors: true,
        }}
        render={({ handleSubmit, submitting, pristine, errors: stateErrors, submitFailed }): JSX.Element => (
          <form onSubmit={handleSubmit} css={addClientContainerStyles.form}>
            <Box paddingX="17px" marginBottom="135px">
              <Field
                name="clientName"
                component={CustomField}
                validate={composeValidators([required, fetchValidator(errors['clientName'], submitValues.clientName)])}
                key={errors['clientName'] ? `clientNameError${submitValues.clientName}` : 'clientName'}
                label={t('client.clientName')}
              />
              <Field name="status" type="checkbox" label="common.availability" component={CustomCheckbox} />
              {stateErrors && submitFailed && <CustomInputError>{t(stateErrors.error)}</CustomInputError>}
              {client && <EditData createdAt={client.createdAt} modifiedAt={client.modifiedAt} />}
              <ButtonContainer spinner={spinner} backTo="/clients" submitting={submitting || pristine} />
            </Box>
          </form>
        )}
      />
    </FormDiv>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AppDispatch>): IDispatchProps => ({
  createClient: (payload: CreateClientDTO): AppDispatch => dispatch(createClient(payload)),
  editClient: (payload: IEditClient): AppDispatch => dispatch(editClient(payload)),
  getClientById: (payload: string): AppDispatch => dispatch(getClientById(payload)),
  clearClient: (): AppDispatch => dispatch(clearClient()),
  clearClientErrors: (): AppDispatch => dispatch(clearClientErrors()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  errors: getClientErrorsSelector(state),
  client: getClientSelector(state),
  spinner: getFetchSpinnerSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClientForm);
