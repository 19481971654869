import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import store from 'store';
import { showSuccessful } from 'store/notify/notify.actions';
import { setPaginationSearchSpinner } from 'store/table/table.actions';
import { api } from 'utils/axiosInstance';
import {
  createClient,
  createClientError,
  createClientSuccess,
  editClient,
  editClientSuccess,
  getAllClients,
  getAllClientsError,
  getAllClientsSuccess,
  getClientById,
  getClientByIdError,
  getClientByIdSuccess,
  getClientsPageable,
  getClientsPageableError,
  getClientsPageableSuccess,
} from './client.actions';

export const getAllClientsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getAllClients.type),
    switchMap(() =>
      from(api.getAllClients$GET$api_client()).pipe(
        switchMap(({ data }) => of(getAllClientsSuccess(data))),
        catchError(({ response }) => of(getAllClientsError({ error: response }))),
      ),
    ),
  );

export const createClientEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(createClient.type),
    switchMap(({ payload }) =>
      from(api.createClient(payload)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'client.success.create' }));
          browserHistory.push('/clients');
          return of(createClientSuccess());
        }),
        catchError(({ response, response: { status } }) =>
          of(createClientError({ error: response, skipMessage: status === 409 })),
        ),
      ),
    ),
  );

export const getClientsPageableEpic: Epic = (action$) =>
  action$.pipe(
    ofType(getClientsPageable.type),
    switchMap(({ payload }) =>
      from(api.getAllClients$GET$api_client_pageable(payload)).pipe(
        switchMap(({ data }) => {
          store.dispatch(setPaginationSearchSpinner(false));
          return of(getClientsPageableSuccess(data));
        }),
        catchError(({ response }) => of(getClientsPageableError({ error: response }))),
      ),
    ),
  );

export const editClientEpic: Epic = (actions$, _, { browserHistory }) =>
  actions$.pipe(
    ofType(editClient.type),
    switchMap(({ payload }) =>
      from(api.editClient(payload.clientId, payload.updateClient)).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'client.success.edit' }));
          browserHistory.push('/clients');
          return of(editClientSuccess());
        }),
        catchError(({ response, response: { status } }) =>
          of(createClientError({ error: response, skipMessage: status === 409 })),
        ),
      ),
    ),
  );

export const getClientByIdEpic: Epic = (actions$, _, { browserHistory }) =>
  actions$.pipe(
    ofType(getClientById.type),
    switchMap(({ payload }) =>
      from(api.getClient(payload)).pipe(
        switchMap(({ data }) => of(getClientByIdSuccess(data))),
        catchError(({ response }) => {
          browserHistory.push('/clients');
          return of(getClientByIdError({ error: response }));
        }),
      ),
    ),
  );

export const clientEpics = combineEpics(
  getClientsPageableEpic,
  getAllClientsEpic,
  createClientEpic,
  editClientEpic,
  getClientByIdEpic,
);
